<template>
  <div class="rescue" v-if="rescue">
    <page-title :title="'Rescue: ' + rescue.name"></page-title>
    <div class="card">
      <div class="card-body">
        <ul class="nav nav-tabs bg-nav-tabs mb-2" v-if="sessionStore.tabs.rescueSize > 1">
          <li class="nav-item" v-for="tab in sessionStore.tabs.rescue" :key="tab.id" >
            <span class="nav-link rounded-0" :class="{active:tab.id===id}">
              <a href="#" @click.prevent="closeRescueTab(tab.id)" class="float-right ml-2" v-if="tab.id !== id">x</a>
              <a href="#" @click.prevent="navigateToRescue(tab.id)">{{tab.name}}</a>
            </span>
          </li>
        </ul>
        <ul class="nav nav-pills bg-nav-pills nav-justified mb-3">
          <li class="nav-item">
            <a href="#profile" data-toggle="tab" class="nav-link rounded-0" @click="$router.push({query:{id,tab:'profile'}})" :class="{active:tab==='profile' || !tab}">Profile</a>
          </li>
          <li class="nav-item">
            <a href="#animals" data-toggle="tab" class="nav-link rounded-0" @click="$router.push({query:{id,tab:'animals'}})" :class="{active:tab==='animals'}">Animals</a>
          </li>
          <li class="nav-item">
            <a href="#appointments" data-toggle="tab" class="nav-link rounded-0" @click="$router.push({query:{id,tab:'appointments'}})" :class="{active:tab==='appointments'}">Appointments</a>
          </li>
          <li class="nav-item">
            <a href="#communication" data-toggle="tab" class="nav-link rounded-0" @click="$router.push({query:{id,tab:'communication'}})" :class="{active:tab==='communication'}">Communication</a>
          </li>
          <li class="nav-item">
            <a href="#history" data-toggle="tab" class="nav-link rounded-0" @click="$router.push({query:{id,tab:'history'}})" :class="{active:tab==='history'}">History</a>
          </li>
        </ul>
        <div class="tab-content">
          <div class="tab-pane" id="profile" :class="{show:tab==='profile' || !tab, active:tab==='profile' || !tab}">
            <h5 class="mb-2 text-uppercase"><i class="uil uil-user-circle mr-1"></i> Rescue Info</h5>
            <div class="row">
              <div class="col-md-6">
                <full-name :name="rescue.name" :editable="true" @name-saved="saveName"/>
              </div>
              <div class="col-md-6">
                <full-service-clinic :clinic="rescue.fullServiceClinic" :editable="true" @clinic-saved="saveClinic"/>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6" v-for="(address, index) in rescue.addresses" :key="index">
                <postal-address :address="address" :editable="true" @address-saved="saveAddress"/>
              </div>
              <div class="col-md-6" v-if="rescue.addresses.length < 2">
                <a href="" @click.prevent="newAddress()">New Address</a>
              </div>
            </div>
            <h5 class="mb-2 text-uppercase"><i class="uil uil-file-alt mr-1"></i> Contact Info</h5>
            <div class="row">
              <div class="col-md-4" v-for="(phone, index) in rescue.phoneNumbers" :key="index">
                <phone-number :phone="phone" :editable="true" @phone-saved="savePhoneNumber"/>
              </div>
              <div class="col-md-4" v-if="rescue.phoneNumbers.length < 3">
                <a href="" @click.prevent="newPhoneNumber()">New Phone Number</a>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4" v-for="(email, index) in rescue.emailAddresses" :key="index">
                <email-address :email="email" :editable="true" @email-saved="saveEmailAddress"/>
              </div>
              <div class="col-md-4" v-if="rescue.emailAddresses.length < 2">
                <a href="" @click.prevent="newEmailAddress()">New Email Address</a>
              </div>
            </div>
            <h5 class="mb-2 text-uppercase"><i class="uil uil-book-alt mr-1"></i> Meta Info</h5>
            <div class="row">
              <div class="col-sm-3">
                <label class="font-weight-bold">Created</label><br/>
                <span>{{rescue.created}}</span>
              </div>
              <div class="col-sm-3">
                <label class="font-weight-bold">Modified</label><br/>
                <span>{{rescue.modified}}</span>
              </div>
              <div class="col-sm-3">
                <label class="font-weight-bold">Version</label><br/>
                <span>{{rescue.version}}</span>
              </div>
            </div>
          </div>
          <div class="tab-pane" id="animals" :class="{show:tab==='animals', active:tab==='animals'}">
            <h5 class="mb-2 text-uppercase"><i class="mdi mdi-dog-side mr-1"></i> Animals</h5>
            <div class="row">
              <div class="col-md-12">
                <table class="table table-sm table-centered mb-0">
                  <thead>
                  <tr>
                    <th>Name</th>
                    <th>Species</th>
                    <th>Breed</th>
                    <th>Age</th>
                    <th>Weight</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="animal in animals" :key="animal._id">
                    <td><a href="" @click.prevent="navigateToPatient(animal._id)"><span :class="{'text-danger':animal.caution.value}">{{animal.name}}</span></a></td>
                    <td>{{animal.species}}</td>
                    <td>{{animal.color}} {{animal.sex}} {{animal.breed}}</td>
                    <td><span v-if="animal.age">{{animal.age.text}}</span></td>
                    <td><span v-if="animal.weight">{{animal.weight}} kg</span></td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="tab-pane" id="appointments" :class="{show:tab==='appointments', active:tab==='appointments'}">
            <h5 class="mb-2 text-uppercase"><i class="uil uil-schedule mr-1"></i> Appointments</h5>
            <div class="row">
              <div class="col-md-12">
                <table class="table table-sm table-centered mb-0">
                  <thead>
                  <tr>
                    <th>Animal</th>
                    <th>Date</th>
                    <th>Type</th>
                    <th>Treatments</th>
                    <th>Status</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(appointment, index) in flattenedAppointments" :key="index">
                    <td><a href="" @click.prevent="navigateToPatientAppointments(appointment.visit.animal._id)"><span :class="{'text-danger':appointment.visit.animal.caution.value}" v-if="appointment.visit.animal">{{appointment.visit.animal.name}}</span><span v-if="!appointment.visit.animal">{{appointment._id}}</span></a></td>
                    <td>{{appointment.date}}</td>
                    <td>{{appointment.type}}</td>
                    <td><span v-for="(treatment, index) in appointment.visit.treatments" :key="treatment.id"><span v-if="treatment.capacity >= 2">{{treatment.name}}</span><span v-if="index < (appointment.visit.treatments.length - 1) && treatment.capacity >= 2">, </span></span></td>
                    <td>{{appointment.visit.status}}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="tab-pane" id="communication" :class="{show:tab==='communication', active:tab==='communication'}">
            <h5 class="mb-2 text-uppercase"><i class="uil uil-postcard mr-1"></i> Communication</h5>
          </div>
          <div class="tab-pane" id="history" :class="{show:tab==='history', active:tab==='history'}">
            <h5 class="mb-2 text-uppercase"><i class="uil uil-history mr-1"></i> Recent Changes</h5>
            <div class="timeline-alt pb-0">
              <div class="timeline-item" v-for="(change, index) in rescue.recentChanges.slice().reverse()" :key="index">
                <i class="mdi mdi-upload bg-info-lighten text-info timeline-icon"></i>
                <div class="timeline-item-info">
                  <a href="#" @click.prevent class="text-info font-weight-bold mb-1 d-block">{{change.modifiedBy}} {{change.result.result}}</a>
                  <small>{{change.result.message}}</small>
                  <p>
                    <small class="text-muted">{{change.result.occurredAt}}</small>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import PageTitle from '@/layout/PageTitle';
import FullName from '@/components/FullName.vue';
import FullServiceClinic from '@/components/FullServiceClinic.vue';
import PostalAddress from '@/components/PostalAddress.vue';
import PhoneNumber from '@/components/PhoneNumber.vue';
import EmailAddress from '@/components/EmailAddress.vue';
import DirectoryAPI from '@/api/DirectoryAPI';
import SchedulingAPI from '@/api/SchedulingAPI';
import { useSession } from '@/store/session';
import { useAlerts } from '@/store/alerts';

export default {
  name: 'RescueDirectoryView',
  components: { PageTitle, FullName, FullServiceClinic, PostalAddress, PhoneNumber, EmailAddress },
  props: {
    id: String,
    tab: String
  }, setup(){
    const sessionStore = useSession();
    const alertStore = useAlerts();
    return { sessionStore, alertStore };
  },
  computed: {
    flattenedAppointments(){
      const result = [];
      if (this.appointments){
        for (const appointment of this.appointments){
          for (const visit of appointment.visits){
            const app = { ...appointment, visit };
            visit.animal = this.findAnimalById(visit.animalId);
            result.push(app);
          }
        }
      }
      return result.sort((a, b) => new Date(b.date) - new Date(a.date));
    }
  },
  created(){
    if (this.id){
      this.fetchRescue(this.id);
    }
  },
  mounted(){

  },
  data(){
    return {
      rescue: null,
      animals: null,
      appointments: null
    };
  },
  methods: {
    navigateToRescue: function(id){
      if (id){
        this.fetchRescue(id);
        this.$router.push({ name: 'directory-rescue', query: { id, tab: this.tab } });
      }
    }, navigateToPatient: function(id){
      if (id){
        this.$router.push({ name: 'directory-patient', query: { id } });
      }
    }, navigateToPatientAppointments: function(id){
      if (id){
        this.$router.push({ name: 'directory-patient', query: { id, tab: 'appointments' } });
      }
    },
    fetchRescue: function(id){
      if (id){
        this.rescue = null;
        DirectoryAPI.fetchRescue(id).then(rs => {
          this.rescue = rs.data;
          this.saveRescueTab(this.rescue);
        });

        DirectoryAPI.fetchRescueAnimals(id).then(rs => {
          this.animals = rs.data;
        });

        SchedulingAPI.fetchRescueAppointments(id).then(rs => {
          this.appointments = rs.data;
        });
      }
    }, newAddress: function(){
      if (this.rescue){
        if (!this.rescue.addresses){
          this.rescue.addresses = [];
        }

        this.rescue.addresses.push({ editing: true });
      }
    }, newPhoneNumber: function(){
      if (this.rescue){
        if (!this.rescue.phoneNumbers){
          this.rescue.phoneNumbers = [];
        }

        this.rescue.phoneNumbers.push({ editing: true });
      }
    }, newEmailAddress: function(){
      if (this.rescue){
        if (!this.rescue.emailAddresses){
          this.rescue.emailAddresses = [];
        }

        this.rescue.emailAddresses.push({ editing: true });
      }
    }, savePhoneNumber: function(updatedPhone){
      DirectoryAPI.saveRescuePhone(this.id, updatedPhone).then(rs => {
        this.alertStore.addAlert({ type: 'success', ...rs.data });
      }).catch(e => {
        console.info(e);
        this.alertStore.addAlert({ type: 'error', ...e });
      });
    }, saveEmailAddress: function(updatedEmail){
      DirectoryAPI.saveRescueEmail(this.id, updatedEmail).then(rs => {
        this.alertStore.addAlert({ type: 'success', ...rs.data });
      }).catch(e => {
        console.info(e);
        this.alertStore.addAlert({ type: 'error', ...e });
      });
    }, saveAddress: function(updatedAddress){
      DirectoryAPI.saveRescueAddress(this.id, updatedAddress).then(rs => {
        this.alertStore.addAlert({ type: 'success', ...rs.data });
        updatedAddress.validationResult = { valid: true };
      }).catch(e => {
        console.info(e);
        this.alertStore.addAlert({ type: 'error', ...e });
        updatedAddress.validationResult = e.response.data;
      });
    }, saveName: function(updatedName){
      DirectoryAPI.saveRescueName(this.id, updatedName).then(rs => {
        this.rescue.name = updatedName;
        this.alertStore.addAlert({ type: 'success', ...rs.data });
        this.saveRescueTab(this.rescue);
      }).catch(e => {
        console.info(e);
        this.alertStore.addAlert({ type: 'error', ...e });
      });
    }, saveClinic: function(updatedClinic){
      DirectoryAPI.saveRescueClinic(this.id, updatedClinic).then(rs => {
        this.alertStore.addAlert({ type: 'success', ...rs.data });
      }).catch(e => {
        console.info(e);
        this.alertStore.addAlert({ type: 'error', ...e });
      });
    }, saveRescueTab: function(rescue){
      this.sessionStore.saveRescueTab({ id: rescue._id, name: rescue.name });
    }, closeRescueTab: function(id){
      this.sessionStore.clearRescueTab(id);
    }, findAnimalById: function(id){
      let result = null;
      if (this.animals){
        for (const animal of this.animals){
          if (animal._id === id){
            result = animal; break;
          }
        }
      }
      return result;
    }
  }
};
</script>
