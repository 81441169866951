import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import AppointmentsView from '@/views/AppointmentsView.vue';
import MedicalView from '@/views/MedicalView.vue';
import DirectoryView from '@/views/DirectoryView.vue';
import ClientsDirectoryView from '@/views/directory/ClientsView.vue';
import ClientsWithPatientsOnSiteDirectoryView from '@/views/directory/PatientsOnSiteView.vue';
import ClientDirectoryView from '@/views/directory/ClientView.vue';
import PatientsDirectoryView from '@/views/directory/PatientsView.vue';
import PatientDirectoryView from '@/views/directory/PatientView.vue';
import RescuesDirectoryView from '@/views/directory/RescuesView.vue';
import RescueDirectoryView from '@/views/directory/RescueView.vue';
import DeveloperView from '@/views/DeveloperView.vue';
import SyncDataDeveloperView from '@/views/developer/SyncDataView.vue';

const routes = [
  {
    path: '/',
    component: HomeView,
    props: true
  }, {
    path: '/appointments',
    name: 'appointments',
    component: AppointmentsView,
    props: route => ({ view: route.query.view, date: route.query.date })
  }, {
    path: '/medical',
    name: 'medical',
    component: MedicalView,
    props: true
  }, {
    path: '/directory',
    name: 'directory',
    component: DirectoryView,
    props: true
  }, {
    path: '/directory/clients',
    name: 'directory-clients',
    component: ClientsDirectoryView,
    props: true
  }, {
    path: '/directory/clients/patients-on-site',
    name: 'directory-clients-patients-on-site',
    component: ClientsWithPatientsOnSiteDirectoryView,
    props: true
  }, {
    path: '/directory/client',
    name: 'directory-client',
    component: ClientDirectoryView,
    props: route => ({ id: route.query.id, tab: route.query.tab })
  }, {
    path: '/directory/patients',
    name: 'directory-patients',
    component: PatientsDirectoryView,
    props: true
  }, {
    path: '/directory/patient',
    name: 'directory-patient',
    component: PatientDirectoryView,
    props: route => ({ id: route.query.id, tab: route.query.tab })
  }, {
    path: '/directory/rescues',
    name: 'directory-rescues',
    component: RescuesDirectoryView,
    props: true
  }, {
    path: '/directory/rescue',
    name: 'directory-rescue',
    component: RescueDirectoryView,
    props: route => ({ id: route.query.id, tab: route.query.tab })
  }, {
    path: '/developer',
    name: 'developer',
    component: DeveloperView,
    props: true
  }, {
    path: '/developer/sync-data',
    name: 'developer-sync-data',
    component: SyncDataDeveloperView,
    props: true
  }
];

export const router = createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes
});
