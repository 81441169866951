<template>
  <div class="patients-on-site">
    <page-title title="Clients With Patients On Site"></page-title>
    <div class="row">
      <div class="card col-md-3" v-for="appointment in sessionStore.appointmentsInProgress" :key="appointment._id">
        <h5 class="card-header">Appointment</h5>
        <div class="card-body">
          <h5 class="card-title mt-0">Owner<span v-if="appointment.owners.length > 1">s</span></h5>
          <p class="mb-1" v-for="owner in appointment.owners" :key="owner.id"><a href="" @click.prevent="navigateToOwner(owner)">{{owner.name}}</a></p>
          <h5 class="card-title mt-2">Patient<span v-if="appointment.patients.length > 1">s</span></h5>
          <p class="mb-1" v-for="patient in appointment.patients" :key="patient.id"><a href="" @click.prevent="navigateToPatient(patient.id)"><span :class="{'text-danger':patient.caution.value}">{{patient.name}}</span></a></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import PageTitle from '@/layout/PageTitle';
import { useSession } from '@/store/session';

export default {
  name: 'ClientsWithPatientsOnSiteDirectoryView',
  components: { PageTitle },
  props: {

  }, setup(){
    const sessionStore = useSession();
    return { sessionStore };
  },
  computed: {

  },
  created(){

  },
  mounted(){

  },
  data(){
    return {};
  },
  methods: {
    navigateToOwner: function(owner){
      if (owner && owner.type && owner.id){
        if (owner.type === 'Client'){
          this.navigateToClient(owner.id);
        } else if (owner.type === 'Rescue'){
          this.navigateToRescue(owner.id);
        } else if (owner.type === 'Veterinarian'){
          this.navigateToVeterinarian(owner.id);
        } else if (owner.type === 'Employee'){
          this.navigateToEmployee(owner.id);
        } else if (owner.type === 'Clinic'){
          this.navigateToClinic(owner.id);
        }
      }
    }, navigateToClient: function(id){
      if (id){
        this.$router.push({ name: 'directory-client', query: { id } });
      }
    }, navigateToRescue: function(id){
      if (id){
        this.$router.push({ name: 'directory-rescue', query: { id } });
      }
    }, navigateToVeterinarian: function(id){
      if (id){
        this.$router.push({ name: 'directory-client', query: { id } });
      }
    }, navigateToEmployee: function(id){
      if (id){
        this.$router.push({ name: 'directory-client', query: { id } });
      }
    }, navigateToClinic: function(id){
      if (id){
        this.$router.push({ name: 'directory-client', query: { id } });
      }
    }, navigateToPatient: function(id){
      if (id){
        this.$router.push({ name: 'directory-patient', query: { id } });
      }
    }
  }
};
</script>
