import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { router } from './router';
import VueMask from '@devindex/vue-mask';
import App from './App.vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faGear } from '@fortawesome/free-solid-svg-icons';
import * as dates from 'date-fns';

library.add(faGear);

const pinia = createPinia();
const app = createApp(App);

app.use(pinia);
app.use(router);
app.use(VueMask);
app.component('font-awesome-icon', FontAwesomeIcon);
app.mount('#app');

app.config.globalProperties.$filters = {
  phone(value){
    if (value && value.length === 10){
      return value.substring(0, 3) + '-' + value.substring(3, 6) + '-' + value.substring(6);
    } else {
      return value;
    }
  }, currency(value){
    if (value){
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
    } else {
      return value;
    }
  }, formatDateForInput(date){
    if (date){
      try {
        const d = dates.parse(date, 'M-d-yyyy', new Date());
        return dates.format(d, 'yyyy-MM-dd');
      } catch (e){
        return date;
      }
    } else {
      return date;
    }
  }, formatDateFromInput(date){
    if (date){
      try {
        const d = dates.parse(date, 'yyyy-MM-dd', new Date());
        return dates.format(d, 'M-d-yyyy');
      } catch (e){
        return date;
      }
    } else {
      return date;
    }
  }
};

app.directive('focus', {
  mounted: (el) => el.focus()
});
