<template>
  <div class="clients">
    <page-title title="Clients"></page-title>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="input-group">
              <input placeholder="Search name, phone, or email" class="form-control" v-model="criteria" @keyup.enter="search(criteria)">
              <button class="btn btn-primary" @click="search(criteria)" v-bind:disabled="!criteria">Search</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="sessionStore.search.clients">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <h4 v-if="sessionStore.search.clients.length > 0">Search results</h4>
            <table class="table table-sm table-centered mb-0">
              <thead>
              <tr>
                <th>Name</th>
                <th>Phone</th>
                <th>Email</th>
              </tr>
              </thead>
              <tbody>
                <tr v-for="client in sessionStore.search.clients" :key="client._id">
                  <td><a href="" @click.prevent="navigateToClient(client._id)">{{client.name}}</a></td>
                  <td><span v-for="(phone, index) in client.phoneNumbers" :key="index">{{phone.type}}: {{$filters.phone(phone.number)}}<span v-if="index < (client.phoneNumbers.length - 1)">, </span></span></td>
                  <td><span v-for="(email, index) in client.emailAddresses" :key="index">{{email.type}}: {{email.address}}<span v-if="index < (client.emailAddresses.length - 1)">, </span></span></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import PageTitle from '@/layout/PageTitle';
import DirectoryAPI from '@/api/DirectoryAPI';
import { useSession } from '@/store/session';
import { useAlerts } from '@/store/alerts';

export default {
  name: 'ClientsDirectoryView',
  components: {
    PageTitle
  }, props: {

  }, setup(){
    const sessionStore = useSession();
    const alertStore = useAlerts();
    return { sessionStore, alertStore };
  }, created(){

  }, mounted(){

  }, data(){
    return {
      criteria: ''
    };
  }, methods: {
    search: function(criteria){
      if (criteria){
        DirectoryAPI.searchClients(criteria).then(rs => {
          this.sessionStore.setClientSearchResults(rs.data);
        }).catch(e => {
          console.info(e);
          this.alertStore.addAlert({ type: 'warning', ...e });
        });
      }
    }, navigateToClient: function(id){
      if (id){
        this.$router.push({ name: 'directory-client', query: { id } });
      }
    }
  }
};
</script>
