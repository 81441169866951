import Factory from './Factory';

export default {
  fetchWorkday(id){
    return Factory.schedulingAPI().get('/workday/' + id);
  }, fetchAppointment(id){
    return Factory.schedulingAPI().get('/appointment/' + id);
  }, fetchClientAppointments(id){
    return Factory.schedulingAPI().get('/client/' + id + '/appointments');
  }, fetchRescueAppointments(id){
    return Factory.schedulingAPI().get('/rescue/' + id + '/appointments');
  }, fetchAnimalAppointments(id){
    return Factory.schedulingAPI().get('/animal/' + id + '/appointments');
  }
};
