<template>
  <h2 class="text-uppercase">{{ title }}</h2>
</template>

<script>
// @ is an alias to /src
import * as dates from 'date-fns';

export default {
  name: 'TitleView',
  components: {},
  props: {
    view: String,
    date: String
  },
  computed: {
    title(){
      let title = '';
      if (this.currentDate != null && this.startDate != null && this.endDate != null){
        if (this.view === 'month'){
          title = dates.format(this.currentDate, 'MMMM yyyy');
        } else if (this.view === 'week'){
          if (dates.isSameMonth(this.startDate, this.endDate)){
            title = dates.format(this.currentDate, 'MMM') + ' ' + dates.format(this.startDate, 'd') + ' - ' + dates.format(this.endDate, 'd') + ' ' + dates.format(this.currentDate, 'yyyy');
          } else {
            if (dates.isSameYear(this.startDate, this.endDate)){
              title = dates.format(this.startDate, 'MMM-d') + ' - ' + dates.format(this.endDate, 'MMM-d') + ' ' + dates.format(this.currentDate, 'yyyy');
            } else {
              title = dates.format(this.startDate, 'MMMM, d yyyy') + ' - ' + dates.format(this.endDate, 'MMMM, d yyyy');
            }
          }
        } else if (this.view === 'day'){
          title = dates.format(this.currentDate, 'MMMM, d yyyy');
        }
      }
      return title;
    }
  },
  created(){
    this.setupDateForView(this.date, this.view);

    this.$watch('date', (newDate) => {
      this.setupDateForView(newDate, this.view);
    });

    this.$watch('view', (newView) => {
      this.setupDateForView(this.date, newView);
    });
  },
  mounted(){

  },
  data(){
    return {
      currentDate: null,
      startDate: null,
      endDate: null
    };
  },
  methods: {
    setupDateForView: function(date, view){
      if (date && view){
        this.currentDate = dates.parse(date, 'yyyy-M-d', new Date());
        if (view === 'month'){
          this.startDate = dates.startOfMonth(this.currentDate);
          this.endDate = dates.endOfMonth(this.currentDate);
        } else if (view === 'week'){
          this.startDate = dates.addDays(dates.startOfWeek(this.currentDate), 1);
          this.endDate = dates.addDays(dates.endOfWeek(this.currentDate), -1);
        } else if (view === 'day'){
          this.startDate = this.currentDate;
          this.endDate = this.currentDate;
        }
      }
    }
  }
};
</script>
