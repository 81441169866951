<template>
  <div class="mb-2 full-service-clinic">
    <label>Full Service Clinic</label>
    <span v-if="!editing"><br/>{{clinic.name}} <a href="" @click.prevent="edit()"><i class="uil  uil-pen mr-1"></i></a></span>
    <div class="input-group" :style="{display: editing ? '': 'none'}">
      <input type="text" ref="cl" class="form-control" @keyup.esc="stopEdit()" v-model="updatedClinic.name" maxlength="100" autocomplete="off">
      <div class="input-group-append">
        <button class="btn btn-primary" :disabled="disabled()" @click="save()">Save</button>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'FullServiceClinic',
  components: {},
  props: {
    clinic: Object,
    editable: Boolean
  },
  computed: {

  },
  created(){
    if (this.clinic){
      this.updatedClinic = { ...this.clinic };
      if (this.clinic.editing){
        this.editing = true;
      }
    }
  },
  mounted(){

  },
  data(){
    return {
      updatedClinic: {},
      editing: false
    };
  },
  methods: {
    edit(){
      this.editing = true;
      this.$forceUpdate();
      setTimeout(() => this.$refs.cl.focus(), 250);
    }, stopEdit(){
      this.editing = false;
    },
    disabled(){
      if (this.editing){
        if (!this.updatedClinic.name){
          return true;
        }
      }
      return false;
    },
    save(){
      this.$emit('clinicSaved', this.updatedClinic, this.clinic);
      this.editing = false;
      Object.assign(this.clinic, this.updatedClinic);
    }
  }
};
</script>
