<template>
  <div class="work-resource">

  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'WorkResource',
  components: {},
  props: {
    resource: Object
  },
  computed: {

  },
  created(){

  },
  mounted(){

  },
  data(){
    return {};
  },
  methods: {}
};
</script>
