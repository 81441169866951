<template>
  <div id="top-nav" class="navbar-custom">
    <div class="topbar container-fluid">
      <div class="d-flex align-items-center gap-lg-2 gap-1">
        <div class="logo-topbar">
          <router-link to="/" class="logo-light">
            <span class="logo-lg">
              <img src="/images/gspd.png" alt="" height="64">
            </span>
            <span class="logo-sm">
              <img src="/images/gspd.png" alt="" height="42">
            </span>
          </router-link>
        </div>
        <button class="button-toggle-menu" @click="toggleMenu()">
          <i class="mdi mdi-menu"></i>
        </button>
        <button class="navbar-toggle" data-bs-toggle="collapse" data-bs-target="#topnav-menu-content">
          <div class="lines">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </button>
      </div>
      <ul class="topbar-menu d-flex align-items-center gap-3">
        <li class="notification-list" v-for="(alert, index) in alertStore.alerts" :key="index">
          <div v-if="index === 0" class="alert my-2" :class="{'alert-success':alert.type==='success', 'alert-danger':alert.type==='error', 'alert-warning':alert.type==='warning', 'alert-info':alert.type==='info'}">
            <span v-if="alert.message">{{alert.message}}</span>
            <span v-if="alert.reason">{{alert.reason}}</span>
            <span v-if="alert.response && alert.response.data && alert.response.data.reason"><br v-if="alert.message"/>{{alert.response.data.reason}}</span>
            <span class="badge badge-info" v-if="alertStore.alerts.length > 1">&nbsp;{{alertStore.alerts.length - 1}}</span>
          </div>
        </li>

        <li class="dropdown">
          <a class="nav-link dropdown-toggle nav-user arrow-none px-2" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
          <span v-if="user"><slot></slot>
              <h5 class="account-user-name"><i class="uil uil-user"></i> {{user.name}}</h5>
              <h6 class="account-position"><span v-for="(role, index) in user.roles" :key="index">{{role}}<span v-if="index < (user.roles.length - 1)">, </span></span></h6>
          </span>
          </a>
          <div class="dropdown-menu dropdown-menu-end dropdown-menu-animated topbar-dropdown-menu profile-dropdown">
            <!-- item-->
            <div class=" dropdown-header noti-title">
              <h6 class="text-overflow m-0">Welcome</h6>
            </div>

            <!-- item-->
            <a href="javascript:void(0);" class="dropdown-item notify-item">
              <i class="uil uil-user me-1"></i>
              <span>My Account</span>
            </a>

            <!-- item-->
            <a href="javascript:void(0);" class="dropdown-item notify-item">
              <i class="mdi mdi-account-edit me-1"></i>
              <span>Settings</span>
            </a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { useAlerts } from '@/store/alerts';
import jQuery from 'jquery';

export default {
  name: 'TopNav',
  components: {

  }, setup(){
    const alertStore = useAlerts();
    return { alertStore };
  }, props: {
    user: Object
  }, data(){
    return {
    };
  }, created(){

  }, mounted(){

  }, methods: {
    toggleMenu(){
      const sidenavSize = jQuery('html').attr('data-sidenav-size');
      if (sidenavSize === 'default'){
        jQuery('html').attr('data-sidenav-size', 'fullscreen');
      } else {
        jQuery('html').attr('data-sidenav-size', 'default');
      }
    }
  }
};
</script>
