import Factory from './Factory';

export default {
  newClinicWebSocketClient(){
    return Factory.newClinicWebSocketClient();
  },
  syncData(backfill){
    return Factory.clinicAPI().post('/developer/sync-data', backfill);
  }, fetchAppointmentsInProgress(){
    return Factory.clinicAPI().get('/appointments-in-progress');
  }
};
