import { defineStore } from 'pinia';

export const useAlerts = defineStore('alerts', {
  state: () => {
    return {
      progress: null,
      interval: 0,
      alerts: []
    };
  }, actions: {
    addAlert(alert){
      if (alert){
        this.alerts.push(alert);
        if (this.interval === 0){
          this.interval = 100;
          this.showProgress();
        }
      }
    }, showProgress(){
      if (this.interval > 0){
        this.progress = this.interval + '%';
        this.interval -= 1;
        setTimeout(() => {
          this.showProgress();
        }, 100);
      }

      if (this.interval === 0 && this.alerts.length > 0){
        this.alerts.shift();
        if (this.alerts.length > 0){
          this.interval = 100;
        }
      }
    }
  }
});
