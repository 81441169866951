<template>
  <div class="mb-2 postal-address">
    <label><span v-if="address">{{address.type}}&nbsp;</span>Address</label> <a href="" v-if="!editing" @click.prevent="edit()"><i class="uil  uil-pen mr-1"></i></a>
    <div v-if="!editing">
      <span v-if="address.lineOne">{{address.lineOne}}<br/></span>
      <span v-if="address.lineTwo">{{address.lineTwo}}<br/></span>
      <span v-if="address.city">{{address.city}}&nbsp;</span>
      <span v-if="address.stateProvince">{{address.stateProvince}}&nbsp;</span>
      <span v-if="address.postalCode">{{address.postalCode}}&nbsp;</span>
    </div>
    <div v-if="editing" :style="{display: editing ? '': 'none'}">
      <div class="input-group mb-1">
        <input type="text" ref="lineOne" class="form-control" @keyup.esc="stopEdit()" v-model="updatedAddress.lineOne" placeholder="Address line one" autocomplete="off">
        <div class="input-group-append">
          <select v-model="updatedAddress.type" class="form-control">
            <option>Home</option>
            <option>Mailing</option>
          </select>
        </div>
      </div>
      <div class="input-group mb-1">
        <input type="text" class="form-control" @keyup.esc="stopEdit()" v-model="updatedAddress.lineTwo" placeholder="Address line two" autocomplete="off">
      </div>
      <div class="row mb-1">
        <div class="col-md-6">
          <input type="text" class="form-control" @keyup.esc="stopEdit()" v-model="updatedAddress.city" placeholder="City" autocomplete="off">
        </div>
        <div class="col-md-3">
          <input type="text" class="form-control" @keyup.esc="stopEdit()" v-model="updatedAddress.stateProvince" placeholder="State" autocomplete="off" maxlength="2">
        </div>
        <div class="col-md-3">
          <input type="text" class="form-control" @keyup.esc="stopEdit()" v-model="updatedAddress.postalCode" placeholder="Zip" v-mask="'#####-####'" maxlength="10" autocomplete="off">
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-sm-12">
          <button class="btn btn-primary float-right" @click="save()">Save</button>
          <button class="btn btn-light float-right mr-1" @click="stopEdit()">Cancel</button>
        </div>
      </div>
      <div class="alert alert-danger" role="alert" v-if="updatedAddress.validationResult && !updatedAddress.validationResult.valid">
        {{updatedAddress.validationResult.reason}}
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'PostalAddress',
  components: {},
  props: {
    address: Object,
    editable: Boolean
  },
  computed: {

  },
  created(){
    if (this.address){
      this.updatedAddress = { ...this.address };
      if (this.address.editing){
        this.editing = true;
      }
      this.$watch('updatedAddress.validationResult', (result) => {
        console.info(result);
        if (result.valid){
          this.editing = false;
          Object.assign(this.address, this.updatedAddress);
        }
      });
    }
  },
  mounted(){

  },
  data(){
    return {
      updatedAddress: {},
      editing: false
    };
  },
  methods: {
    edit(){
      this.editing = true;
      this.$forceUpdate();
      setTimeout(() => this.$refs.lineOne.focus(), 250);
    }, stopEdit(){
      this.editing = false;
    }, save(){
      this.$emit('addressSaved', this.updatedAddress, this.address);
    }
  }
};
</script>
