<template>
  <div class="patients">
    <page-title title="Patients"></page-title>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="input-group">
              <input placeholder="Search name or breed" class="form-control" v-model="criteria" @keyup.enter="search(criteria)">
              <button class="btn btn-primary" @click="search(criteria)" v-bind:disabled="!criteria">Search</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="sessionStore.search.patients">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <h4 v-if="sessionStore.search.patients.length > 0">Search results</h4>
            <table class="table table-sm table-centered mb-0">
              <thead>
              <tr>
                <th>Name</th>
                <th>Species</th>
                <th>Breed</th>
                <th>Age</th>
                <th>Weight</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="patient in sessionStore.search.patients" :key="patient._id">
                <td><a href="" @click.prevent="navigateToPatient(patient._id)"><span :class="{'text-danger':patient.caution.value}">{{patient.name}}</span></a></td>
                <td>{{patient.species}}</td>
                <td>{{patient.color}} {{patient.sex}} {{patient.breed}}</td>
                <td><span v-if="patient.age">{{patient.age.text}}</span></td>
                <td><span v-if="patient.weight">{{patient.weight}} kg</span></td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import PageTitle from '@/layout/PageTitle';
import DirectoryAPI from '@/api/DirectoryAPI';
import { useSession } from '@/store/session';
import { useAlerts } from '@/store/alerts';

export default {
  name: 'PatientsDirectoryView',
  components: {
    PageTitle
  }, props: {
    user: Object
  }, setup(){
    const sessionStore = useSession();
    const alertStore = useAlerts();
    return { sessionStore, alertStore };
  }, created(){

  }, mounted(){

  }, data(){
    return {
      criteria: ''
    };
  }, methods: {
    search: function(criteria){
      if (criteria){
        DirectoryAPI.searchAnimals(criteria).then((rs) => {
          this.sessionStore.setPatientSearchResults(rs.data);
        }).catch(e => {
          console.info(e);
          this.alertStore.addAlert({ type: 'warning', ...e });
        });
      }
    }, navigateToPatient: function(id){
      this.$router.push({ name: 'directory-patient', query: { id } });
    }
  }
};
</script>
