<template>
  <div class="directory">
    <page-title title="Directory"></page-title>
  </div>
</template>

<script>
// @ is an alias to /src
import PageTitle from '@/layout/PageTitle';

export default {
  name: 'DirectoryView',
  components: {
    PageTitle
  }, props: {
    user: Object
  }, created(){

  }, mounted(){

  }, data(){
    return {

    };
  }, methods: {

  }
};
</script>
