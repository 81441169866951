<template>
  <div class="mb-2 email-address">
    <label><span v-if="email">{{email.type}}&nbsp;</span>Email Address</label>
    <span v-if="!editing"><br/>{{email.address}} <a href="" @click.prevent="edit()"><i class="uil  uil-pen mr-1"></i></a></span>
    <div class="input-group" :style="{display: editing ? '': 'none'}">
      <div class="input-group-prepend">
        <select v-model="updatedEmail.type" class="form-control">
          <option>Personal</option>
          <option>Work</option>
        </select>
      </div>
      <input type="text" ref="ea" class="form-control" @keyup.esc="stopEdit()" v-model="updatedEmail.address" maxlength="150" autocomplete="off">
      <div class="input-group-append">
        <button class="btn btn-primary" :disabled="disabled()" @click="save()">Save</button>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'EmailAddress',
  components: {},
  props: {
    email: Object,
    editable: Boolean
  },
  computed: {

  },
  created(){
    if (this.email){
      this.updatedEmail = { ...this.email };
      if (this.email.editing){
        this.editing = true;
      }
    }
  },
  mounted(){

  },
  data(){
    return {
      updatedEmail: {},
      editing: false
    };
  },
  methods: {
    edit(){
      this.editing = true;
      this.$forceUpdate();
      setTimeout(() => this.$refs.ea.focus(), 250);
    }, stopEdit(){
      this.editing = false;
    },
    disabled(){
      if (this.editing){
        if (!this.updatedEmail.type){
          return true;
        }
        if (!this.updatedEmail.address){
          return true;
        }
        return this.updatedEmail.address === this.email.address && this.updatedEmail.type === this.email.type;
      }
      return false;
    },
    save(){
      this.$emit('emailSaved', this.updatedEmail);
      this.editing = false;
      Object.assign(this.email, this.updatedEmail);
    }
  }
};
</script>
