<template>
  <div class="sync-data-developer">
    <page-title title="Sync Data"></page-title>
    <div class="row" v-if="connected">
      <div class="col-sm-12">
        <div class="row">
          <div class="col-sm-2">
            <label class="font-weight-bold">Start Date</label>
            <input type="date" class="form-control" v-model="startDate">
          </div>
          <div class="col-sm-2">
            <label class="font-weight-bold">End Date</label>
            <input type="date" class="form-control" v-model="endDate">
          </div>
          <div class="col-sm-2">
            <label>&nbsp;</label><br/>
            <button class="btn btn-primary" @click="startSync()">Start Sync</button>
          </div>
        </div>
        <div class="row" v-if="latestUpdate">
          <div class="col-sm-12">
            <table class="table table-sm table-bordered">
              <thead>
                <tr>
                  <th scope="col">Date</th>
                  <th scope="col">Runtime</th>
                  <th scope="col">Appointments</th>
                  <th scope="col">Clients</th>
                  <th scope="col">Animals</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{latestUpdate.currentDate}}</td>
                  <td>{{latestUpdate.stats.runtime}}</td>
                  <td>{{latestUpdate.stats.appointments}}</td>
                  <td>{{latestUpdate.stats.clients}}</td>
                  <td>{{latestUpdate.stats.animals}}</td>
                </tr>
                <tr v-if="latestUpdate.message">
                  <td colspan="5">{{latestUpdate.message}}</td>
                </tr>
                <tr v-for="(update, index) in list" :key="index">
                  <td>{{update.startDate}}-{{update.endDate}}</td>
                  <td>{{update.stats.runtime}}</td>
                  <td>{{update.stats.appointments}}</td>
                  <td>{{update.stats.clients}}</td>
                  <td>{{update.stats.animals}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import PageTitle from '@/layout/PageTitle';
import ClinicAPI from '@/api/ClinicAPI';
import { useAlerts } from '@/store/alerts';

export default {
  name: 'SyncDataDeveloperView',
  components: {
    PageTitle
  }, props: {
    user: Object
  }, setup(){
    const alertStore = useAlerts();
    return { alertStore };
  },
  computed: {

  }, created(){
    this.createWebSocketClient();
  }, mounted(){

  }, unmounted(){
    if (this.client){
      this.client.deactivate();
      this.connected = false;
    }
  }, data(){
    return {
      client: null,
      startDate: null,
      endDate: null,
      connected: false,
      latestUpdate: null,
      list: []
    };
  }, methods: {
    createWebSocketClient: async function(){
      const client = await ClinicAPI.newClinicWebSocketClient();
      this.client = client;

      client.onConnect = (frame) => {
        this.connected = true;
        client.subscribe('/topic/developer/data-sync-update', message => {
          this.latestUpdate = JSON.parse(message.body);
        });
      };
      client.onWebSocketError = (event) => {
        console.info(event);
        this.connected = this.client.connected;
        if (!this.connected){
          this.createWebSocketClient();
        }
      };

      client.activate();
    },
    startSync: function(){
      if (this.latestUpdate){
        this.list.push(this.latestUpdate);
      }
      const backfill = { dataOption: 'appointments', startDate: this.startDate, endDate: this.endDate };
      ClinicAPI.syncData(backfill).then(rs => {
        this.alertStore.addAlert({ type: 'info', message: 'Sync Started' });
      }).catch(e => {
        console.info(e);
        this.alertStore.addAlert({ type: 'error', ...e });
      });
    }
  }
};
</script>
