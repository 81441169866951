<template>
  <!-- start page title -->
  <div class="row">
    <div class="col-12">
      <div class="page-title-box">
        <slot></slot>
        <h4 class="page-title">{{title}}</h4>
      </div>
    </div>
  </div>
  <!-- end page title -->
</template>

<script>
// @ is an alias to /src

export default {
  name: 'PageTitle',
  components: {

  }, props: {
    title: String
  }, created(){

  }, mounted(){

  }, data(){
    return {

    };
  }, methods: {

  }
};
</script>
