import Factory from './Factory';

export default {
  loginSettings(){
    return Factory.loginAPI().get('/login-settings');
  },
  loginUserWithOAuth(oauth){
    return Factory.loginAPI().post('/login', oauth);
  }
};
