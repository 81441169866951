import Factory from './Factory';

export default {
  searchEntities(criteria){
    return Factory.directoryAPI().post('/entities/search', criteria);
  }, fetchEntity(id){
    return Factory.directoryAPI().get('/entity/' + id);
  }, fetchEntityName(id){
    return Factory.directoryAPI().get('/entity/' + id + '/name');
  }, fetchBasicEntity(id){
    return Factory.directoryAPI().get('/entity/' + id + '/basic');
  }, searchClients(criteria){
    return Factory.directoryAPI().post('/clients/search', criteria);
  }, fetchClient(id){
    return Factory.directoryAPI().get('/client/' + id);
  }, fetchClientAnimals(id){
    return Factory.directoryAPI().get('/client/' + id + '/animals');
  }, saveClientName(id, name){
    return Factory.directoryAPI().post('/client/' + id + '/name', name);
  }, saveClientClinic(id, clinic){
    return Factory.directoryAPI().post('/client/' + id + '/clinic', clinic);
  }, saveClientPhone(id, phone){
    return Factory.directoryAPI().post('/client/' + id + '/phone', phone);
  }, saveClientEmail(id, email){
    return Factory.directoryAPI().post('/client/' + id + '/email', email);
  }, saveClientAddress(id, address){
    return Factory.directoryAPI().post('/client/' + id + '/address', address);
  }, fetchRescues(){
    return Factory.directoryAPI().get('/rescues');
  }, fetchRescue(id){
    return Factory.directoryAPI().get('/rescue/' + id);
  }, fetchRescueAnimals(id){
    return Factory.directoryAPI().get('/rescue/' + id + '/animals');
  }, saveRescueName(id, name){
    return Factory.directoryAPI().post('/rescue/' + id + '/name', name);
  }, saveRescueClinic(id, clinic){
    return Factory.directoryAPI().post('/rescue/' + id + '/clinic', clinic);
  }, saveRescuePhone(id, phone){
    return Factory.directoryAPI().post('/rescue/' + id + '/phone', phone);
  }, saveRescueEmail(id, email){
    return Factory.directoryAPI().post('/rescue/' + id + '/email', email);
  }, saveRescueAddress(id, address){
    return Factory.directoryAPI().post('/rescue/' + id + '/address', address);
  }, searchAnimals(criteria){
    return Factory.directoryAPI().post('/animals/search', criteria);
  }, fetchAnimal(id){
    return Factory.directoryAPI().get('/animal/' + id);
  }, fetchAnimalName(id){
    return Factory.directoryAPI().get('/animal/' + id + '/name');
  }, saveAnimalName(id, name){
    return Factory.directoryAPI().post('/animal/' + id + '/name', name);
  }, saveAnimalSpecies(id, species){
    return Factory.directoryAPI().post('/animal/' + id + '/species', species);
  }, saveAnimalAge(id, age){
    return Factory.directoryAPI().post('/animal/' + id + '/age', age);
  }, saveAnimalColor(id, color){
    return Factory.directoryAPI().post('/animal/' + id + '/color', color);
  }, saveAnimalSex(id, sex){
    return Factory.directoryAPI().post('/animal/' + id + '/sex', sex);
  }, saveAnimalBreed(id, breed){
    return Factory.directoryAPI().post('/animal/' + id + '/breed', breed);
  }, saveAnimalCaution(id, caution){
    return Factory.directoryAPI().post('/animal/' + id + '/caution', caution);
  }, saveAnimalDeceased(id, deceased){
    return Factory.directoryAPI().post('/animal/' + id + '/deceased', deceased);
  }
};
