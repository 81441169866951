<template>
  <div class="home">
    <page-title title="Home"></page-title>
  </div>
</template>

<script>
// @ is an alias to /src
import PageTitle from '@/layout/PageTitle';

export default {
  name: 'HomeView',
  components: {
    PageTitle
  }, props: {
    user: Object
  }, created(){

  }, mounted(){

  }, data(){
    return {

    };
  }, methods: {

  }
};
</script>
