<template>
  <div class="rescues">
    <page-title title="Rescues"></page-title>
    <div class="row">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <table class="table table-sm table-centered mb-0">
              <thead>
              <tr>
                <th>Name</th>
                <th>Phone</th>
                <th>Email</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="rescue in sessionStore.search.rescues" :key="rescue._id">
                <td><a href="" @click.prevent="navigateToRescue(rescue._id)">{{rescue.name}}</a></td>
                <td><span v-for="(phone, index) in rescue.phoneNumbers" :key="index">{{phone.type}}: {{$filters.phone(phone.number)}}<span v-if="index < (rescue.phoneNumbers.length - 1)">, </span></span></td>
                <td><span v-for="(email, index) in rescue.emailAddresses" :key="index">{{email.type}}: {{email.address}}<span v-if="index < (rescue.emailAddresses.length - 1)">, </span></span></td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import PageTitle from '@/layout/PageTitle';
import DirectoryAPI from '@/api/DirectoryAPI';
import { useSession } from '@/store/session';

export default {
  name: 'RescuesDirectoryView',
  components: {
    PageTitle
  }, props: {

  }, setup(){
    const sessionStore = useSession();
    return { sessionStore };
  }, created(){
    this.search();
  }, mounted(){

  }, data(){
    return {

    };
  }, methods: {
    search: function(){
      DirectoryAPI.fetchRescues().then(rs => {
        this.sessionStore.setRescueSearchResults(rs.data);
      });
    }, navigateToRescue: function(id){
      if (id){
        this.$router.push({ name: 'directory-rescue', query: { id } });
      }
    }
  }
};
</script>
