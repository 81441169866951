<template>
  <div class="developer">
    <page-title title="Developer"></page-title>
    <font-awesome-icon icon="fa-solid fa-gear" spin/>
  </div>
</template>

<script>
// @ is an alias to /src
import PageTitle from '@/layout/PageTitle';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  name: 'DeveloperView',
  components: {
    FontAwesomeIcon,
    PageTitle
  }, props: {
    user: Object
  }, created(){

  }, mounted(){

  }, data(){
    return {

    };
  }, methods: {

  }
};
</script>
