import { defineStore } from 'pinia';

export const useSession = defineStore('session', {
  state: () => {
    return {
      user: null,
      search: {},
      tabs: { client: {}, clientSize: 0, rescue: {}, rescueSize: 0, patient: {}, patientSize: 0 },
      appointmentsInProgress: {}
    };
  }, actions: {
    setUser(user){
      this.user = user;
    }, setClientSearchResults(results){
      this.search.clients = results;
    }, setPatientSearchResults(results){
      this.search.patients = results;
    }, setRescueSearchResults(results){
      this.search.rescues = results;
    }, saveClientTab(clientTab){
      if (!this.tabs.client[clientTab.id]){
        this.tabs.clientSize += 1;
      }
      this.tabs.client[clientTab.id] = clientTab;
    }, clearClientTab(id){
      if (this.tabs.client[id]){
        this.tabs.clientSize -= 1;
      }
      delete this.tabs.client[id];
    }, saveRescueTab(rescueTab){
      if (!this.tabs.rescue[rescueTab.id]){
        this.tabs.rescueSize += 1;
      }
      this.tabs.rescue[rescueTab.id] = rescueTab;
    }, clearRescueTab(id){
      if (this.tabs.rescue[id]){
        this.tabs.rescueSize -= 1;
      }
      delete this.tabs.rescue[id];
    }, savePatientTab(patientTab){
      if (!this.tabs.patient[patientTab.id]){
        this.tabs.patientSize += 1;
      }
      this.tabs.patient[patientTab.id] = patientTab;
    }, clearPatientTab(id){
      if (this.tabs.patient[id]){
        this.tabs.patientSize -= 1;
      }
      delete this.tabs.patient[id];
    }, includeAppointmentInProgress(appointment){
      if (!this.appointmentsInProgress[appointment._id]){
        this.appointmentsInProgress[appointment._id] = appointment;
      } else {
        const app = this.appointmentsInProgress[appointment._id];
        for (const patient in appointment.patients){
          let found = false;
          for (const p in app.patients){
            if (patient.id === p.id){
              found = true;
            }
          }
          if (!found){
            app.patients.put(patient);
          }
        }
      }
    }, removeAppointmentInProgress(appointment){
      if (this.appointmentsInProgress[appointment._id]){
        this.appointmentsInProgress[appointment._id] = appointment;
        const app = this.appointmentsInProgress[appointment._id];
        for (const patient in appointment.patients){
          app.patients = app.patients.filter(el => el.id === patient.id);
        }
        if (app.patients.length === 0){
          delete this.appointmentsInProgress[appointment._id];
        }
      }
    }
  }
});
