<template>
  <div class="mb-2 name">
    <label>Deceased</label>
    <span v-if="!editing"><br/><span>{{deceased.value === 'true' || (deceased.value && deceased.value !== 'false') ? deceased.dateOfDeath : 'No'}}</span> <a href="" @click.prevent="edit()"><i class="uil  uil-pen mr-1"></i></a></span>
    <p v-if="!editing">{{deceased.notes}}</p>
    <div :style="{display: editing ? '': 'none'}">
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" id="deceasedYes" value="true" v-model="updatedDeceased.value">
        <label class="form-check-label" for="deceasedYes">Yes</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" id="deceasedNo" value="false" v-model="updatedDeceased.value">
        <label class="form-check-label" for="deceasedNo">No</label>
      </div>
      <div class="input-group mb-1 col-md-4 pl-0">
        <div class="input-group-prepend">
          <span class="input-group-text">Date of Death</span>
        </div>
        <input type="date" class="form-control" v-model="updatedDeceased.dateOfDeath" @keyup.esc="stopEdit()" :disabled="updatedDeceased.value === 'false'">
      </div>
      <textarea type="text" ref="nm" class="form-control" @keyup.esc="stopEdit()" v-model="updatedDeceased.notes" maxlength="500" autocomplete="off"></textarea>
      <button class="btn btn-primary mt-1" :disabled="disabled()" @click="save()">Save</button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import * as dates from 'date-fns';

export default {
  name: 'PatientDeceased',
  components: {},
  props: {
    deceased: Object,
    editable: Boolean
  },
  computed: {

  },
  created(){
    this.reset();
  },
  mounted(){
    this.$watch('updatedDeceased.value', (newValue) => {
      if (newValue === 'true'){
        this.updatedDeceased.dateOfDeath = dates.format(new Date(), 'yyyy-MM-dd');
      } else if (newValue === 'false'){
        this.updatedDeceased.dateOfDeath = null;
      }
    });

    this.$watch('updatedDeceased.dateOfDeath', (newDateOfDeath) => {
      if (!newDateOfDeath || newDateOfDeath === ''){
        this.updatedDeceased.value = 'false';
      }
    });
  },
  data(){
    return {
      updatedDeceased: {},
      editing: false
    };
  },
  methods: {
    edit(){
      this.editing = true;
      this.$forceUpdate();
      setTimeout(() => this.$refs.nm.focus(), 250);
    }, stopEdit(){
      this.editing = false;
    }, reset(){
      if (this.deceased){
        this.updatedDeceased = { ...this.deceased };
        if (this.updatedDeceased.dateOfDeath){
          this.updatedDeceased.dateOfDeath = this.$filters.formatDateForInput(this.updatedDeceased.dateOfDeath);
        }
        if (this.deceased.editing){
          this.editing = true;
        }
      }
    },
    disabled(){
      if (this.editing){
        if (this.updatedDeceased.value === this.deceased.value && this.updatedDeceased.notes === this.deceased.notes){
          return this.$filters.formatDateFromInput(this.updatedDeceased.dateOfDeath) === this.deceased.dateOfDeath;
        }
      }
      return false;
    },
    save(){
      this.$emit('deceasedSaved', this.updatedDeceased);
      this.editing = false;
      const copy = { ...this.updatedDeceased };
      copy.dateOfDeath = this.$filters.formatDateFromInput(copy.dateOfDeath);
      Object.assign(this.deceased, copy);
      this.$forceUpdate();
    }
  }
};
</script>
