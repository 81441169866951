<template>
  <div class="mb-2 species">
    <label>Species</label>
    <span v-if="!editing"><br/>{{species}} <a href="" @click.prevent="edit()"><i class="uil  uil-pen mr-1"></i></a></span>
    <div class="input-group" :style="{display: editing ? '': 'none'}">
      <select ref="spec" class="form-control" @keyup.esc="stopEdit()" v-model="updatedSpecies">
        <option>Canine</option>
        <option>Feline</option>
        <option disabled>──────────</option>
        <option>Chicken</option>
        <option>Guinea Pig</option>
        <option>Mouse</option>
        <option>Pig</option>
        <option>Rabbit</option>
        <option>Rat</option>
      </select>
      <div class="input-group-append">
        <button class="btn btn-primary" :disabled="disabled()" @click="save()">Save</button>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'PatientSpecies',
  components: {},
  props: {
    species: String,
    editable: Boolean
  },
  computed: {

  },
  created(){
    if (this.species){
      this.updatedSpecies = this.species;
    }
  },
  mounted(){

  },
  data(){
    return {
      updatedSpecies: null,
      editing: false
    };
  },
  methods: {
    edit(){
      this.editing = true;
      this.$forceUpdate();
      setTimeout(() => this.$refs.spec.focus(), 250);
    }, stopEdit(){
      this.editing = false;
    },
    disabled(){
      if (this.editing){
        if (!this.updatedSpecies || this.updatedSpecies === this.species){
          return true;
        }
      }
      return false;
    },
    save(){
      this.$emit('speciesSaved', this.updatedSpecies);
      this.editing = false;
    }
  }
};
</script>
