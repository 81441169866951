<template>
  <div class="appointments">
    <page-title title="Appointments"></page-title>
    <div class="row">
      <div class="col-12">
        <div class="card m-0">
          <div class="card-body p-2">
            <div class="row">
              <div class="col-sm-4">
                <div class="btn-group float-left" role="group" aria-label="appointment view button group">
                  <button type="button" class="btn btn-primary" @click="moveBack()">&lt;</button>
                  <button type="button" class="btn btn-primary" @click="today()">Today</button>
                  <button type="button" class="btn btn-primary" @click="moveForward()">&gt;</button>
                </div>
              </div>
              <div class="col-sm-4">
                <date-title :view="view" :date="date"/>
              </div>
              <div class="col-sm-4">
                <div class="btn-group float-right" role="group" aria-label="appointment view button group">
                  <button type="button" class="btn btn-primary" :class="{active:view=='month'}" @click="changeView('month')">Month</button>
                  <button type="button" class="btn btn-primary" :class="{active:view=='week'}" @click="changeView('week')">Week</button>
                  <button type="button" class="btn btn-primary" :class="{active:view=='day'}" @click="changeView('day')">Day</button>
                </div>
              </div>
            </div>
            <div class="row" v-if="view==='day' && dataView">
              <div class="col-12 table-responsive">
                <table class="table table-bordered table-sm m-0">
                  <thead>
                  <tr>
                    <th style="width:100%" class="text-center" v-if="dataView.date">{{df.format(dataView.date, 'eee')}}</th>
                  </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="p-0">
                        <work-day :view="view" :day="dataView"/>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row" v-if="view==='week' && dataView">
              <div class="col-12 table-responsive">
                <table class="table table-bordered table-sm m-0">
                  <thead>
                    <tr>
                      <th style="width:20%" class="text-center" v-for="day in dataView.days" :key="day.date">{{df.format(day.date, 'eee M/d')}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <work-week :view="view" :week="dataView"/>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row" v-if="view==='month' && dataView">
              <div class="col-12 table-responsive">
                <table class="table table-bordered table-sm">
                  <thead>
                    <tr>
                      <th class="text-center" style="width:20%">Mon</th>
                      <th class="text-center" style="width:20%">Tue</th>
                      <th class="text-center" style="width:20%">Wed</th>
                      <th class="text-center" style="width:20%">Thu</th>
                      <th class="text-center" style="width:20%">Fri</th>
                    </tr>
                  </thead>
                  <tbody>
                    <work-week :view="view" :week="week" v-for="week in dataView.weeks" :key="week" />
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import PageTitle from '@/layout/PageTitle';
import DateTitle from '@/components/appointments/DateTitle';
import WorkWeek from '@/components/appointments/WorkWeek';
import WorkDay from '@/components/appointments/WorkDay';
import * as dates from 'date-fns';

export default {
  name: 'AppointmentsView',
  components: {
    PageTitle, DateTitle, WorkWeek, WorkDay
  },
  props: {
    view: String,
    date: String
  },
  computed: {

  },
  created(){
    if (this.date){
      this.setupDateForView(dates.parse(this.date, 'yyyy-M-d', new Date()), this.view);
    } else {
      this.today();
    }
  },
  mounted(){

  },
  data(){
    return {
      currentDate: null,
      dataView: null,
      df: dates
    };
  },
  methods: {
    changeView: function(newView){
      if (this.view !== newView){
        this.setupDateForView(this.currentDate, newView);
        this.$router.push({ name: 'appointments', query: { view: newView, date: dates.format(this.currentDate, 'yyyy-M-d') } });
      }
    },
    moveBack: function(){
      if (this.view === 'month'){
        this.currentDate = dates.addMonths(this.currentDate, -1);
      } else if (this.view === 'week'){
        this.currentDate = dates.addWeeks(this.currentDate, -1);
      } else if (this.view === 'day'){
        this.currentDate = dates.addDays(this.currentDate, -1);
        if (dates.isWeekend(this.currentDate)){
          this.currentDate = dates.addDays(this.currentDate, -2);
        }
      }
      this.setupDateForView(this.currentDate, this.view);
      this.setUrl();
    },
    moveForward: function(){
      if (this.view === 'month'){
        this.currentDate = dates.addMonths(this.currentDate, 1);
      } else if (this.view === 'week'){
        this.currentDate = dates.addWeeks(this.currentDate, 1);
      } else if (this.view === 'day'){
        this.currentDate = dates.addDays(this.currentDate, 1);
        if (dates.isWeekend(this.currentDate)){
          this.currentDate = dates.addDays(this.currentDate, 2);
        }
      }
      this.setupDateForView(this.currentDate, this.view);
      this.setUrl();
    },
    today: function(){
      this.setupDateForView(new Date(), this.view);
      this.setUrl();
    },
    setupDateForView: function(date, view){
      this.currentDate = date;
      if (view === 'month'){
        this.dataView = this.createDataAsMonth(this.currentDate);
      } else if (view === 'week'){
        this.dataView = this.createDataAsWeek(this.currentDate);
      } else if (view === 'day'){
        this.dataView = this.createDataAsDay(this.currentDate);
      }
    },
    setUrl: function(){
      this.$router.push({ name: 'appointments', query: { view: this.view, date: dates.format(this.currentDate, 'yyyy-M-d') } });
    }, createDataAsMonth: function(date){
      const month = { weeks: [] };

      let start = dates.startOfMonth(date);
      const end = dates.endOfMonth(date);
      if (dates.isWeekend(start)){
        start = dates.nextMonday(start);
      }
      month.dateThatActivated = start;

      while (start.getTime() < end.getTime()){
        const week = this.createDataAsWeek(start);
        week.dateThatActivated = month.dateThatActivated;
        month.weeks.push(week);
        start = dates.addDays(start, 7);
      }
      return month;
    }, createDataAsWeek: function(date){
      const week = { days: [] };

      let start = dates.addDays(dates.startOfWeek(date), 1);
      const end = dates.addDays(dates.endOfWeek(date), -1);
      while (start.getTime() < end.getTime()){
        week.days.push(this.createDataAsDay(start));
        start = dates.addDays(start, 1);
      }
      return week;
    }, createDataAsDay: function(date){
      const day = {
        date
      };

      return day;
    }
  }
};
</script>
