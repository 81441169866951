<template>
  <div class="mb-2 age">
    <label>Age</label>
    <span v-if="!editing"><br/>{{age.text}} <a href="" @click.prevent="edit()"><i class="uil  uil-pen mr-1"></i></a></span>
    <div class="input-group" :style="{display: editing ? '': 'none'}">
      <input type="text" ref="yr" class="form-control" @keyup.esc="stopEdit()" v-model="updatedAge.years" maxlength="3" autocomplete="off">
      <input type="text" class="form-control" @keyup.esc="stopEdit()" v-model="updatedAge.months" maxlength="3" autocomplete="off">
      <div class="input-group-append">
        <button class="btn btn-primary" :disabled="disabled()" @click="save()">Save</button>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'PatientAge',
  components: {},
  props: {
    age: Object,
    editable: Boolean
  },
  computed: {

  },
  created(){
    if (this.age){
      this.updatedAge = { ...this.age };
    } else {
      this.updatedAge = {};
    }
  },
  mounted(){

  },
  data(){
    return {
      updatedAge: null,
      editing: false
    };
  },
  methods: {
    edit(){
      this.editing = true;
      this.$forceUpdate();
      setTimeout(() => this.$refs.yr.focus(), 250);
    }, stopEdit(){
      this.editing = false;
    },
    disabled(){
      if (this.editing){
        if (!this.updatedAge.months && !this.updatedAge.years){
          return true;
        } else if (this.updatedAge.months === this.age.months && this.updatedAge.years === this.age.years){
          return true;
        }
      }
      return false;
    },
    save(){
      this.$emit('ageSaved', this.updatedAge);
      this.editing = false;
      Object.assign(this.age, this.updatedAge);
    }
  }
};
</script>
