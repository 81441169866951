<template>
  <div class="wrapper">
    <top-nav v-bind:user="sessionStore.user"><div class="float-end"><i class="uil-data-sharing text-success" :class="{'text-success':connected}"></i></div></top-nav>
    <left-nav></left-nav>
    <div class="content-page" v-if="!sessionStore.user">
      <span v-if="authenticating">Authenticating <font-awesome-icon icon="fa-solid fa-gear" spin/></span>
    </div>
    <div class="content-page" v-if="sessionStore.user">
      <div class="content">

        <!-- Start Content-->
        <div class="container-fluid">
          <router-view/>
        </div>
      </div>
    </div>
    <div class="position-fixed fixed-top" v-if="alertStore.alerts.length > 0">
      <div class="progress" style="height:2px" v-if="alertStore.interval > 0">
        <div class="progress-bar" role="progressbar" :style="{width:alertStore.progress}"></div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import LeftNav from '@/layout/LeftNav';
import TopNav from '@/layout/TopNav';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import * as msal from '@azure/msal-browser';
import Factory from '@/api/Factory';
import LoginAPI from '@/api/LoginAPI';
import ClinicAPI from '@/api/ClinicAPI';
import { useSession } from '@/store/session';
import { useAlerts } from '@/store/alerts';

export default {
  name: 'App',
  components: {
    LeftNav,
    TopNav,
    FontAwesomeIcon
  }, setup(){
    const sessionStore = useSession();
    const alertStore = useAlerts();
    return { sessionStore, alertStore };
  }, computed: {

  }, created(){

  }, async mounted(){
    if (!this.isAuthenticated()){
      await this.login();
    }
  }, unmounted(){
    if (this.client){
      this.client.deactivate();
      this.connected = false;
    }
  }, data(){
    return {
      client: null,
      authenticating: false,
      connected: false
    };
  }, methods: {
    isAuthenticated(){
      if (!this.sessionStore.user){
        const localUser = localStorage.getItem('user');
        if (localUser){
          const user = JSON.parse(localUser);
          if (user && new Date(user.expires).getTime() > new Date().getTime()){
            this.sessionStore.setUser(user);
            Factory.setAuthorization(user.token);
            this.userLoggedIn();
          } else {
            localStorage.removeItem('user');
          }
        } else {
          console.debug('local user not found');
        }
      }
      return this.sessionStore.user;
    }, async login(){
      console.info('starting user login');
      this.authenticating = true;
      const settings = (await LoginAPI.loginSettings()).data;
      const msalInstance = new msal.PublicClientApplication({ auth: { clientId: settings.clientId, authority: settings.authority } });

      msalInstance.handleRedirectPromise().then((rs) => {
        if (rs != null){
          msalInstance.acquireTokenSilent({ scopes: rs.scopes, account: rs.account }).then((tokenRs) => {
            const oauth = { accessToken: tokenRs.accessToken, tokenType: tokenRs.tokenType };
            LoginAPI.loginUserWithOAuth(oauth).then((rs) => {
              const user = JSON.stringify(rs.data);
              localStorage.setItem('user', user);
              this.sessionStore.setUser(rs.data);
              Factory.setAuthorization(rs.data.token);
              this.userLoggedIn();
              this.authenticating = false;
            });
          });
        } else {
          msalInstance.loginRedirect({});
        }
      }).catch((error) => {
        console.error(error);
        this.authenticating = false;
      });
    }, userLoggedIn: async function(){
      const { data } = await ClinicAPI.fetchAppointmentsInProgress();
      for (const appointment of data){
        this.sessionStore.includeAppointmentInProgress(appointment);
      }

      await this.createWebSocketClient();
    }, createWebSocketClient: async function(){
      const client = await ClinicAPI.newClinicWebSocketClient();
      this.client = client;

      client.onConnect = (frame) => {
        this.connected = true;
        client.subscribe('/topic/appointment/checkin-complete', message => {
          this.sessionStore.includeAppointmentInProgress(JSON.parse(message.body));
        });
        client.subscribe('/topic/appointment/complete', message => {
          this.sessionStore.removeAppointmentInProgress(JSON.parse(message.body));
        });
      };
      client.onWebSocketError = (event) => {
        console.info(event);
        this.connected = this.client.connected;
        if (!this.connected){
          this.createWebSocketClient();
        }
      };

      client.activate();
    }
  }
};
</script>
