<template>
  <div class="patient" v-if="patient">
    <page-title :title="'Patient: ' + patient.name">
      <div class="page-title-right" v-if="selectedAppointment">
        <ol class="breadcrumb m-0">
          <li class="breadcrumb-item"><a href="#profile" data-toggle="tab" @click="selectedAppointment=null;$router.push({query:{id,tab:'profile'}});">Profile</a></li>
          <li class="breadcrumb-item"><a href="#appointments" data-toggle="tab" @click="selectedAppointment=null;$router.push({query:{id,tab:'appointments'}});">Appointments</a></li>
          <li class="breadcrumb-item active">Current</li>
        </ol>
      </div>
    </page-title>
    <div class="card" v-if="!selectedAppointment">
      <div class="card-body">
        <ul class="nav nav-tabs bg-nav-tabs mb-2" v-if="sessionStore.tabs.patientSize > 1">
          <li class="nav-item" v-for="tab in sessionStore.tabs.patient" :key="tab.id" >
            <span class="nav-link rounded-0" :class="{active:tab.id===id}">
              <a href="#" @click.prevent="closePatientTab(tab.id)" class="float-end ms-1" v-if="tab.id !== id">x</a>
              <a href="#" @click.prevent="navigateToPatient(tab.id)">{{tab.name}}</a>
            </span>
          </li>
        </ul>
        <ul class="nav nav-pills bg-nav-pills nav-justified mb-3">
          <li class="nav-item">
            <a href="#profile" data-toggle="tab" class="nav-link rounded-0" @click="$router.push({query:{id,tab:'profile'}})" :class="{active:tab==='profile' || !tab}">Profile</a>
          </li>
          <li class="nav-item">
            <a href="#pets" data-toggle="tab" class="nav-link rounded-0" @click="$router.push({query:{id,tab:'owners'}})" :class="{active:tab==='owners'}">Owners</a>
          </li>
          <li class="nav-item">
            <a href="#appointments" data-toggle="tab" class="nav-link rounded-0" @click="$router.push({query:{id,tab:'appointments'}})" :class="{active:tab==='appointments'}">Appointments</a>
          </li>
          <li class="nav-item">
            <a href="#communication" data-toggle="tab" class="nav-link rounded-0" @click="$router.push({query:{id,tab:'medical'}})" :class="{active:tab==='medical'}">Medical</a>
          </li>
          <li class="nav-item">
            <a href="#history" data-toggle="tab" class="nav-link rounded-0" @click="$router.push({query:{id,tab:'history'}})" :class="{active:tab==='history'}">History</a>
          </li>
        </ul>
        <div class="tab-content">
          <div class="tab-pane" id="profile" :class="{show:tab==='profile' || !tab, active:tab==='profile' || !tab}">
            <h5 class="mb-2 text-uppercase"><i class="mdi mdi-dog-side mr-1"></i> Patient Info</h5>
            <div class="row">
              <div class="col-md-4">
                <patient-name :name="patient.name" :editable="true" @name-saved="saveName"/>
              </div>
              <div class="col-md-3">
                <patient-species :species="patient.species" :editable="true" @species-saved="saveSpecies"/>
              </div>
              <div class="col-md-3">
                <patient-age :age="patient.age" :editable="true" @age-saved="saveAge"/>
              </div>
              <div class="col-md-2">
                <label>Weight</label>
                <span v-if="patient.weight"><br/>{{patient.weight}} kg</span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <patient-color :color="patient.color" :editable="true" @color-saved="saveColor"/>
              </div>
              <div class="col-md-3">
                <patient-sex :sex="patient.sex" :editable="true" @sex-saved="saveSex"/>
              </div>
              <div class="col-md-5">
                <patient-breed :breed="patient.breed" :editable="true" @breed-saved="saveBreed"/>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <patient-caution :caution="patient.caution" :editable="true" @caution-saved="saveCaution"/>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <patient-deceased :deceased="patient.deceased" :editable="true" @deceased-saved="saveDeceased"/>
              </div>
            </div>
            <h5 class="mb-2 text-uppercase"><i class="uil uil-book-alt mr-1"></i> Meta Info</h5>
            <div class="row">
              <div class="col-sm-3">
                <label class="font-weight-bold">Created</label><br/>
                <span>{{patient.created}}</span>
              </div>
              <div class="col-sm-3">
                <label class="font-weight-bold">Modified</label><br/>
                <span>{{patient.modified}}</span>
              </div>
              <div class="col-sm-3">
                <label class="font-weight-bold">Version</label><br/>
                <span>{{patient.version}}</span>
              </div>
            </div>
          </div>
          <div class="tab-pane" id="history" :class="{show:tab==='owners', active:tab==='owners'}">
            <h5 class="mb-2 text-uppercase"><i class="uil uil-users-alt mr-1"></i> Owners</h5>
            <table class="table table-sm table-centered mb-0">
              <thead>
              <tr>
                <th>Name</th>
                <th>Phone</th>
                <th>Email</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="owner in owners" :key="owner._id">
                <td><a href="" @click.prevent="navigateToOwner(owner)">{{owner.name}}</a></td>
                <td><span v-for="(phone, index) in owner.phoneNumbers" :key="index">{{phone.type}}: {{$filters.phone(phone.number)}}<span v-if="index < (owner.phoneNumbers.length - 1)">, </span></span></td>
                <td><span v-for="(email, index) in owner.emailAddresses" :key="index">{{email.type}}: {{email.address}}<span v-if="index < (owner.emailAddresses.length - 1)">, </span></span></td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="tab-pane" id="appointments" :class="{show:tab==='appointments', active:tab==='appointments'}">
            <h5 class="mb-2 text-uppercase"><i class="uil uil-schedule mr-1"></i> Appointments</h5>
            <div class="row">
              <div class="col-md-12">
                <table class="table table-sm table-centered mb-0">
                  <thead>
                  <tr>
                    <th>Date</th>
                    <th>Type</th>
                    <th>Treatments</th>
                    <th>Status</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(appointment, index) in flattenedAppointments" :key="index">
                    <td><a href="" @click.prevent="selectAppointment(appointment._id)">{{appointment.date}}</a></td>
                    <td>{{appointment.type}}</td>
                    <td><span v-for="(treatment, index) in appointment.visit.treatments" :key="treatment.id"><span v-if="treatment.capacity >= 2">{{treatment.name}}</span><span v-if="index < (appointment.visit.treatments.length - 1) && treatment.capacity >= 2">, </span></span></td>
                    <td>{{appointment.visit.status}}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="tab-pane" id="history" :class="{show:tab==='history', active:tab==='history'}">
            <h5 class="mb-2 text-uppercase"><i class="uil uil-history mr-1"></i> Recent Changes</h5>
            <div class="timeline-alt pb-0">
              <div class="timeline-item" v-for="(change, index) in patient.recentChanges.slice().reverse()" :key="index">
                <i class="mdi mdi-upload bg-info-lighten text-info timeline-icon"></i>
                <div class="timeline-item-info">
                  <a href="#" @click.prevent class="text-info font-weight-bold mb-1 d-block">{{change.modifiedBy}} {{change.result.result}}</a>
                  <small>{{change.result.message}}</small>
                  <p>
                    <small class="text-muted">{{change.result.occurredAt}}</small>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <appointment-card v-if="selectedAppointment" :id="selectedAppointment._id" :animal-id="id"></appointment-card>
  </div>
</template>

<script>
// @ is an alias to /src

import PageTitle from '@/layout/PageTitle.vue';
import AppointmentCard from '@/components/appointments/AppointmentCard.vue';
import PatientName from '@/components/directory/patient/Name.vue';
import PatientSpecies from '@/components/directory/patient/Species.vue';
import PatientAge from '@/components/directory/patient/Age.vue';
import PatientColor from '@/components/directory/patient/Color.vue';
import PatientSex from '@/components/directory/patient/Sex.vue';
import PatientBreed from '@/components/directory/patient/Breed.vue';
import PatientCaution from '@/components/directory/patient/Caution.vue';
import PatientDeceased from '@/components/directory/patient/Deceased.vue';
import DirectoryAPI from '@/api/DirectoryAPI';
import SchedulingAPI from '@/api/SchedulingAPI';
import { useSession } from '@/store/session';
import { useAlerts } from '@/store/alerts';

export default {

  name: 'PatientDirectoryView',
  components: { AppointmentCard, PageTitle, PatientName, PatientSpecies, PatientAge, PatientColor, PatientSex, PatientBreed, PatientCaution, PatientDeceased },
  props: {
    id: String,
    tab: String
  }, setup(){
    const sessionStore = useSession();
    const alertStore = useAlerts();
    return { sessionStore, alertStore };
  },
  computed: {
    flattenedAppointments(){
      const result = [];
      if (this.appointments){
        for (const appointment of this.appointments){
          for (const visit of appointment.visits){
            if (visit.animalId === this.patient._id){
              const app = { ...appointment, visit };
              result.push(app);
            }
          }
        }
      }
      return result.sort((a, b) => new Date(b.date) - new Date(a.date));
    }
  },
  created(){
    if (this.id){
      this.fetchPatient(this.id);
    }
  },
  mounted(){

  },
  data(){
    return {
      patient: null,
      owners: null,
      appointments: null,
      selectedAppointment: null
    };
  },
  methods: {
    navigateToPatient: function(id){
      if (id){
        this.fetchPatient(id);
        this.$router.push({ name: 'directory-patient', query: { id, tab: this.tab } });
      }
    }, navigateToOwner: function(owner){
      if (owner && owner.type && owner._id){
        if (owner.type === 'Client'){
          this.navigateToClient(owner._id);
        } else if (owner.type === 'Rescue'){
          this.navigateToRescue(owner._id);
        } else if (owner.type === 'Veterinarian'){
          this.navigateToVeterinarian(owner._id);
        } else if (owner.type === 'Employee'){
          this.navigateToEmployee(owner._id);
        } else if (owner.type === 'Clinic'){
          this.navigateToClinic(owner._id);
        }
      }
    }, navigateToClient: function(id){
      if (id){
        this.$router.push({ name: 'directory-client', query: { id } });
      }
    }, navigateToRescue: function(id){
      if (id){
        this.$router.push({ name: 'directory-rescue', query: { id } });
      }
    }, navigateToVeterinarian: function(id){
      if (id){
        this.$router.push({ name: 'directory-client', query: { id } });
      }
    }, navigateToEmployee: function(id){
      if (id){
        this.$router.push({ name: 'directory-client', query: { id } });
      }
    }, navigateToClinic: function(id){
      if (id){
        this.$router.push({ name: 'directory-client', query: { id } });
      }
    }, fetchPatient: function(id){
      if (id){
        this.patient = null;
        DirectoryAPI.fetchAnimal(id).then(rs => {
          this.patient = rs.data;
          this.savePatientTab(this.patient);
          this.owners = [];

          for (const ownerId of this.patient.owners){
            DirectoryAPI.fetchEntity(ownerId).then(rs => {
              this.owners.push(rs.data);
            });
          }
        });

        SchedulingAPI.fetchAnimalAppointments(id).then(rs => {
          this.appointments = rs.data;
        });
      }
    }, selectAppointment: function(appointmentId){
      if (this.appointments){
        for (const appointment of this.appointments){
          if (appointment._id === appointmentId){
            this.selectedAppointment = appointment;
            break;
          }
        }
      }
    }, saveName: function(updatedName){
      DirectoryAPI.saveAnimalName(this.id, updatedName).then(rs => {
        this.patient.name = updatedName;
        this.alertStore.addAlert({ type: 'success', ...rs.data });
        this.savePatientTab(this.patient);
      }).catch(e => {
        console.info(e);
        this.alertStore.addAlert({ type: 'error', ...e });
      });
    }, saveSpecies: function(updatedSpecies){
      DirectoryAPI.saveAnimalSpecies(this.id, updatedSpecies).then(rs => {
        this.patient.species = updatedSpecies;
        this.alertStore.addAlert({ type: 'success', ...rs.data });
      }).catch(e => {
        console.info(e);
        this.alertStore.addAlert({ type: 'error', ...e });
      });
    }, saveAge: function(updatedAge){
      DirectoryAPI.saveAnimalAge(this.id, updatedAge).then(rs => {
        this.patient.age = updatedAge;
        this.alertStore.addAlert({ type: 'success', ...rs.data });
      }).catch(e => {
        console.info(e);
        this.alertStore.addAlert({ type: 'error', ...e });
      });
    }, saveColor: function(updatedColor){
      DirectoryAPI.saveAnimalColor(this.id, updatedColor).then(rs => {
        this.patient.color = updatedColor;
        this.alertStore.addAlert({ type: 'success', ...rs.data });
      }).catch(e => {
        console.info(e);
        this.alertStore.addAlert({ type: 'error', ...e });
      });
    }, saveSex: function(updatedSex){
      DirectoryAPI.saveAnimalSex(this.id, updatedSex).then(rs => {
        this.patient.sex = updatedSex;
        this.alertStore.addAlert({ type: 'success', ...rs.data });
      }).catch(e => {
        console.info(e);
        this.alertStore.addAlert({ type: 'error', ...e });
      });
    }, saveBreed: function(updatedBreed){
      DirectoryAPI.saveAnimalBreed(this.id, updatedBreed).then(rs => {
        this.patient.breed = updatedBreed;
        this.alertStore.addAlert({ type: 'success', ...rs.data });
      }).catch(e => {
        console.info(e);
        this.alertStore.addAlert({ type: 'error', ...e });
      });
    }, saveCaution: function(updatedCaution){
      DirectoryAPI.saveAnimalCaution(this.id, updatedCaution).then(rs => {
        this.alertStore.addAlert({ type: 'success', ...rs.data });
      }).catch(e => {
        console.info(e);
        this.alertStore.addAlert({ type: 'error', ...e });
      });
    }, saveDeceased: function(updatedDeceased){
      DirectoryAPI.saveAnimalDeceased(this.id, updatedDeceased).then(rs => {
        this.alertStore.addAlert({ type: 'success', ...rs.data });
      }).catch(e => {
        console.info(e);
        this.alertStore.addAlert({ type: 'error', ...e });
      });
    }, savePatientTab: function(patient){
      this.sessionStore.savePatientTab({ id: patient._id, name: patient.name });
    }, closePatientTab: function(id){
      this.sessionStore.clearPatientTab(id);
    }
  }
};
</script>
