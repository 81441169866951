<template>
  <div class="card">
    <div class="card-body">
      <h5 class="card-title text-uppercase"><i class="uil uil-schedule mr-1"></i> Appointment<font-awesome-icon icon="fa-solid fa-gear" spin class="mx-3" v-if="loading"/><span v-if="appointment"><span class="mx-3">{{appointment.date}}</span>{{appointment.status}}</span></h5>
      <div class="row" v-if="appointment">
        <div class="col-12 mb-2">
          <label>Notes</label><br/>
          <span>{{appointment.notes}}</span>
        </div>
      </div>
      <div class="row mb-2" v-if="appointment">
        <div class="col-md-6">
          <label>Owner<span v-if="appointment.owners.length > 1">s</span></label><br/>
          <ul class="nav nav-underline">
            <li class="nav-item" v-for="owner in appointment.owners" :key="owner.id">
              <a href="" class="nav-link" aria-current="page" @click.prevent="navigateToOwner(owner)">{{owner.name}}</a>
            </li>
          </ul>
        </div>
        <div class="col-md-6" v-if="appointment.visits.length > 1">
          <label>Patients</label><br/>
          <ul class="nav nav-underline">
            <li class="nav-item" v-for="v in appointment.visits" :key="v.animalId">
              <a href="" class="nav-link" :class="{active:v.animalId===visit.animalId}" aria-current="page" @click.prevent="selectVisit(v.animalId)">{{v.animalName}}</a>
            </li>
          </ul>
        </div>
      </div>
      <h5 class="mb-2 text-uppercase" v-if="visit"><i class="uil uil-book-open mr-1"></i> Visit Info for {{visit.animalName}}</h5>
      <div class="row" v-if="visit">
        <div class="col-12 mb-2">
          <label>Notes</label><br/>
          <span>{{visit.notes}}</span>
        </div>
      </div>
      <div class="row" v-if="visit">
        <div class="col-sm-12">
          <table class="table table-sm table-centered mb-3">
            <thead>
            <tr>
              <th class="ps-0">Treatment</th>
              <th>Type</th>
              <th>Notes</th>
              <th>Price</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="treatment in visit.treatments" :key="treatment.id">
              <td class="ps-0">{{treatment.name}}</td>
              <td>{{treatment.type}}</td>
              <td>{{treatment.notes}}</td>
              <td>{{$filters.currency(treatment.price)}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <h5 class="mb-2 text-uppercase" v-if="appointment"><i class="uil uil-book-alt mr-1"></i> Meta Info</h5>
      <div class="row" v-if="appointment">
        <div class="col-sm-3">
          <label class="font-weight-bold">Created</label><br/>
          <span>{{appointment.created}}</span>
        </div>
        <div class="col-sm-3">
          <label class="font-weight-bold">Modified</label><br/>
          <span>{{appointment.modified}}</span>
        </div>
        <div class="col-sm-3">
          <label class="font-weight-bold">Version</label><br/>
          <span>{{appointment.version}}</span>
        </div>
        <div class="col-sm-3">
          <label class="font-weight-bold">Capacity</label><br/>
          <span>{{appointment.capacity}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import SchedulingAPI from '@/api/SchedulingAPI';
import DirectoryAPI from '@/api/DirectoryAPI';
import { useSession } from '@/store/session';
import { useAlerts } from '@/store/alerts';

export default {
  name: 'AppointmentCard',
  components: {},
  props: {
    id: String,
    animalId: String
  }, setup(){
    const sessionStore = useSession();
    const alertStore = useAlerts();
    return { sessionStore, alertStore };
  },
  computed: {

  },
  created(){
    if (this.id){
      this.loading = true;
      SchedulingAPI.fetchAppointment(this.id).then((rs) => {
        this.appointment = rs.data;

        this.appointment.owners.forEach((ownerId) => {
          DirectoryAPI.fetchBasicEntity(ownerId).then((rs) => {
            const owner = { ...rs.data };
            this.appointment.owners[this.appointment.owners.indexOf(ownerId)] = owner;
          }).catch(e => {
            console.info(e);
            this.alertStore.addAlert({ type: 'error', ...e });
          });
        });

        this.appointment.visits.forEach((visit) => {
          DirectoryAPI.fetchAnimalName(visit.animalId).then((rs) => {
            visit.animalName = rs.data;
          }).catch(e => {
            console.info(e);
            this.alertStore.addAlert({ type: 'error', ...e });
          });
        });

        if (this.animalId){
          this.selectVisit(this.animalId);
        }

        if (!this.visit && this.appointment.visits.length > 0){
          this.visit = this.appointment.visits[0]; // default to first visit
        }
        this.loading = false;
      }).catch(e => {
        console.info(e);
        this.loading = false;
        this.alertStore.addAlert({ type: 'error', ...e });
      });
    }
  },
  mounted(){

  },
  data(){
    return {
      loading: false,
      editing: false,
      appointment: null,
      visit: null
    };
  },
  methods: {
    selectVisit: function(animalId){
      for (const visit of this.appointment.visits){
        if (visit.animalId === animalId){
          this.visit = visit;
          break;
        }
      }
    }, navigateToOwner: function(owner){
      if (owner && owner.type && owner.id){
        if (owner.type === 'Client'){
          this.navigateToClient(owner.id);
        } else if (owner.type === 'Rescue'){
          this.navigateToRescue(owner.id);
        } else if (owner.type === 'Veterinarian'){
          this.navigateToVeterinarian(owner.id);
        } else if (owner.type === 'Employee'){
          this.navigateToEmployee(owner.id);
        } else if (owner.type === 'Clinic'){
          this.navigateToClinic(owner.id);
        }
        this.$emit('navigatedToOwner');
      }
    }, navigateToClient: function(id){
      if (id){
        this.$router.push({ name: 'directory-client', query: { id } });
      }
    }, navigateToRescue: function(id){
      if (id){
        this.$router.push({ name: 'directory-rescue', query: { id } });
      }
    }, navigateToVeterinarian: function(id){
      if (id){
        this.$router.push({ name: 'directory-client', query: { id } });
      }
    }, navigateToEmployee: function(id){
      if (id){
        this.$router.push({ name: 'directory-client', query: { id } });
      }
    }, navigateToClinic: function(id){
      if (id){
        this.$router.push({ name: 'directory-client', query: { id } });
      }
    }
  }
};
</script>
