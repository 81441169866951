<template>
  <div class="mb-2 color">
    <label>Color</label>
    <span v-if="!editing"><br/>{{color}} <a href="" @click.prevent="edit()"><i class="uil  uil-pen mr-1"></i></a></span>
    <div class="input-group" :style="{display: editing ? '': 'none'}">
      <input type="text" ref="nm" class="form-control" @keyup.esc="stopEdit()" v-model="updatedColor" maxlength="50" autocomplete="off">
      <div class="input-group-append">
        <button class="btn btn-primary" :disabled="disabled()" @click="save()">Save</button>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'ColorName',
  components: {},
  props: {
    color: String,
    editable: Boolean
  },
  computed: {

  },
  created(){
    if (this.color){
      this.updatedColor = this.color;
    }
  },
  mounted(){

  },
  data(){
    return {
      updatedColor: null,
      editing: false
    };
  },
  methods: {
    edit(){
      this.editing = true;
      this.$forceUpdate();
      setTimeout(() => this.$refs.nm.focus(), 250);
    }, stopEdit(){
      this.editing = false;
    },
    disabled(){
      if (this.editing){
        if (!this.updatedColor || this.updatedColor === this.color){
          return true;
        }
      }
      return false;
    },
    save(){
      this.$emit('colorSaved', this.updatedColor);
      this.editing = false;
    }
  }
};
</script>
