<template>
  <div id="left-nav" class="leftside-menu">

    <router-link to="/" class="logo">
        <img src="/images/gspd.png" alt="logo" height="64">
    </router-link>

    <div class="h-100" id="leftside-menu-container" data-simplebar>

      <ul class="side-nav" v-if="!sessionStore.user">
        <li class="side-nav-title side-nav-item text-center text-light">GOOD SAM PET DOC</li>
      </ul>

      <ul class="side-nav" v-if="sessionStore.user">
        <li class="side-nav-title side-nav-item text-center">GOOD SAM PET DOC</li>

        <li class="side-nav-item">
          <router-link :to="{name: 'appointments', query: {view:'week', date: df.format(new Date(), 'yyyy-M-d')}}" class="side-nav-link">
            <i class="uil uil-schedule"></i>
            <span>Appointments</span>
          </router-link>
        </li>

        <li class="side-nav-item">
          <router-link to="/directory" class="side-nav-link">
            <i class="uil uil-database-alt"></i>
            <span>Directory</span>
          </router-link>
          <ul class="side-nav-second-level" aria-expanded="false">
            <li><router-link to="/directory/clients">Clients</router-link></li>
            <ul class="side-nav-third-level" aria-expanded="false" v-if="sessionStore.search.clients && sessionStore.search.clients.length > 0">
              <li><router-link to="/directory/clients">Search Results <span class="badge text-bg-primary">{{sessionStore.search.clients.length}}</span></router-link></li>
            </ul>
            <ul class="side-nav-third-level" aria-expanded="false" v-if="patientsOnSiteCount && patientsOnSiteCount > 0">
              <li><router-link to="/directory/clients/patients-on-site">Patients On Site <span class="badge text-bg-primary">{{patientsOnSiteCount}}</span></router-link></li>
            </ul>
            <li><router-link to="/directory/patients">Patients</router-link></li>
            <ul class="side-nav-third-level" aria-expanded="false" v-if="sessionStore.search.patients && sessionStore.search.patients.length > 0">
              <li><router-link to="/directory/patients">Search Results <span class="badge text-bg-primary">{{sessionStore.search.patients.length}}</span></router-link></li>
            </ul>
            <li><router-link to="/directory/rescues">Rescues <span class="badge text-bg-primary" v-if="sessionStore.search.rescues && sessionStore.search.rescues.length > 0">{{sessionStore.search.rescues.length}}</span></router-link></li>
          </ul>
        </li>

        <li class="side-nav-item">
          <router-link to="/medical" class="side-nav-link">
            <i class="uil uil-ambulance"></i>
            <span>Medical</span>
          </router-link>
        </li>
        <li class="side-nav-item" v-if="sessionStore.user.roles.includes('Engineer')">
          <router-link to="/developer" class="side-nav-link">
            <i class="uil uil-user-hard-hat"></i>
            <span>Developer</span>
          </router-link>
          <ul class="side-nav-second-level" aria-expanded="false">
            <li><router-link to="/developer/sync-data">Sync Data</router-link></li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import * as dates from 'date-fns';
import { useSession } from '@/store/session';

export default {
  name: 'LeftNav',
  components: {

  }, props: {
    user: Object
  }, setup(){
    const sessionStore = useSession();
    return { sessionStore };
  }, computed: {
    patientsOnSiteCount(){
      let result = 0;
      if (this.sessionStore.appointmentsInProgress){
        for (const appointment of Object.values(this.sessionStore.appointmentsInProgress)){
          if (appointment.patients){
            result += appointment.patients.length;
          }
        }
      }
      return result;
    }
  }, created(){

  }, mounted(){

  }, unmounted(){

  }, data(){
    return {
      df: dates
    };
  }, methods: {

  }
};
</script>
