<template>
  <div class="mb-2 species">
    <label>Sex</label>
    <span v-if="!editing"><br/>{{sex}} <a href="" @click.prevent="edit()"><i class="uil  uil-pen mr-1"></i></a></span>
    <div class="input-group" :style="{display: editing ? '': 'none'}">
      <select ref="spec" class="form-control" @keyup.esc="stopEdit()" v-model="updatedSex">
        <option>Male</option>
        <option>Female</option>
        <option>Neutered Male</option>
        <option>Spayed Female</option>
      </select>
      <div class="input-group-append">
        <button class="btn btn-primary" :disabled="disabled()" @click="save()">Save</button>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'PatientSex',
  components: {},
  props: {
    sex: String,
    editable: Boolean
  },
  computed: {

  },
  created(){
    if (this.sex){
      this.updatedSex = this.sex;
    }
  },
  mounted(){

  },
  data(){
    return {
      updatedSex: null,
      editing: false
    };
  },
  methods: {
    edit(){
      this.editing = true;
      this.$forceUpdate();
      setTimeout(() => this.$refs.spec.focus(), 250);
    }, stopEdit(){
      this.editing = false;
    },
    disabled(){
      if (this.editing){
        if (!this.updatedSex || this.updatedSex === this.sex){
          return true;
        }
      }
      return false;
    },
    save(){
      this.$emit('sexSaved', this.updatedSex);
      this.editing = false;
    }
  }
};
</script>
