<template>
  <div class="mb-2 phone-number">
    <label><span v-if="phone">{{phone.type}}&nbsp;</span>Phone Number</label>
    <span v-if="!editing"><br/>{{$filters.phone(phone.number)}} <a href="" @click.prevent="edit()"><i class="uil  uil-pen mr-1"></i></a></span>
    <div class="input-group" :style="{display: editing ? '': 'none'}">
      <div class="input-group-prepend">
        <select v-model="updatedPhone.type" class="form-control">
          <option>Cell</option>
          <option>Home</option>
          <option>Work</option>
        </select>
      </div>
      <input type="text" ref="pn" class="form-control" v-mask="'###-###-####'" @keyup.esc="stopEdit()" v-model="updatedPhone.number" maxlength="12" autocomplete="off">
      <div class="input-group-append">
        <button class="btn btn-primary" :disabled="disabled()" @click="save()">Save</button>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'PhoneNumber',
  components: {},
  props: {
    phone: Object,
    editable: Boolean
  },
  computed: {

  },
  created(){
    if (this.phone){
      this.updatedPhone = { ...this.phone };
      if (this.phone.editing){
        this.editing = true;
      }
    }
  },
  mounted(){

  },
  data(){
    return {
      updatedPhone: {},
      editing: false
    };
  },
  methods: {
    edit(){
      this.editing = true;
      this.$forceUpdate();
      setTimeout(() => this.$refs.pn.focus(), 250);
    }, stopEdit(){
      this.editing = false;
    },
    disabled(){
      if (this.editing){
        if (!this.updatedPhone.type){
          return true;
        }
        if (!this.updatedPhone.number || this.updatedPhone.number.length !== 12){
          return true;
        }
        return this.updatedPhone.number === this.$filters.phone(this.phone.number) && this.updatedPhone.type === this.phone.type;
      }
      return false;
    },
    save(){
      this.$emit('phoneSaved', this.updatedPhone, this.phone);
      this.editing = false;
      Object.assign(this.phone, this.updatedPhone);
    }
  }
};
</script>
