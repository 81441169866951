<template>
  <div class="client" v-if="client">
    <page-title :title="'Client: ' + client.name">
      <div class="page-title-right" v-if="selectedAppointment">
        <ol class="breadcrumb m-0">
          <li class="breadcrumb-item"><a href="#profile" data-toggle="tab" @click="selectedAppointment=null;$router.push({query:{id,tab:'profile'}});">Profile</a></li>
          <li class="breadcrumb-item"><a href="#appointments" data-toggle="tab" @click="selectedAppointment=null;$router.push({query:{id,tab:'appointments'}});">Appointments</a></li>
          <li class="breadcrumb-item active">Current</li>
        </ol>
      </div>
    </page-title>
    <div class="card" v-if="!selectedAppointment">
      <div class="card-body">
        <ul class="nav nav-tabs bg-nav-tabs mb-2" v-if="sessionStore.tabs.clientSize > 1">
          <li class="nav-item" v-for="tab in sessionStore.tabs.client" :key="tab.id" >
            <span class="nav-link rounded-0" :class="{active:tab.id==id}">
              <a href="#" @click.prevent="closeClientTab(tab.id)" class="float-end ms-1" v-if="tab.id !== id">x</a>
              <a href="#" @click.prevent="navigateToClient(tab.id)">{{tab.name}}</a>
            </span>
          </li>
        </ul>
        <ul class="nav nav-pills bg-nav-pills nav-justified mb-3">
          <li class="nav-item">
            <a href="#profile" data-toggle="tab" class="nav-link rounded-0" @click="$router.push({query:{id,tab:'profile'}})" :class="{active:tab==='profile' || !tab}">Profile</a>
          </li>
          <li class="nav-item">
            <a href="#pets" data-toggle="tab" class="nav-link rounded-0" @click="$router.push({query:{id,tab:'pets'}})" :class="{active:tab==='pets'}">Pets</a>
          </li>
          <li class="nav-item">
            <a href="#appointments" data-toggle="tab" class="nav-link rounded-0" @click="$router.push({query:{id,tab:'appointments'}})" :class="{active:tab==='appointments'}">Appointments</a>
          </li>
          <li class="nav-item">
            <a href="#communication" data-toggle="tab" class="nav-link rounded-0" @click="$router.push({query:{id,tab:'communication'}})" :class="{active:tab=='communication'}">Communication</a>
          </li>
          <li class="nav-item">
            <a href="#history" data-toggle="tab" class="nav-link rounded-0" @click="$router.push({query:{id,tab:'history'}})" :class="{active:tab=='history'}">History</a>
          </li>
        </ul>
        <div class="tab-content">
          <div class="tab-pane" id="profile" :class="{show:tab=='profile' || !tab, active:tab=='profile' || !tab}">
            <h5 class="mb-2 text-uppercase"><i class="uil uil-user mr-1"></i> Personal Info</h5>
            <div class="row">
              <div class="col-md-6">
                <full-name :name="client.name" :editable="true" @name-saved="saveName"/>
              </div>
              <div class="col-md-6">
                <full-service-clinic :clinic="client.fullServiceClinic" :editable="true" @clinic-saved="saveClinic"/>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6" v-for="(address, index) in client.addresses" :key="index">
                <postal-address :address="address" :editable="true" @address-saved="saveAddress"/>
              </div>
              <div class="col-md-6" v-if="client.addresses.length < 2">
                <a href="" @click.prevent="newAddress()">New Address</a>
              </div>
            </div>
            <h5 class="mb-2 text-uppercase"><i class="uil uil-file-alt mr-1"></i> Contact Info</h5>
            <div class="row">
              <div class="col-md-4" v-for="(phone, index) in client.phoneNumbers" :key="index">
                <phone-number :phone="phone" :editable="true" @phone-saved="savePhoneNumber"/>
              </div>
              <div class="col-md-4" v-if="client.phoneNumbers.length < 3">
                <a href="" @click.prevent="newPhoneNumber()">New Phone Number</a>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4" v-for="(email, index) in client.emailAddresses" :key="index">
                <email-address :email="email" :editable="true" @email-saved="saveEmailAddress"/>
              </div>
              <div class="col-md-4" v-if="client.emailAddresses.length < 2">
                <a href="" @click.prevent="newEmailAddress()">New Email Address</a>
              </div>
            </div>
            <h5 class="mb-2 text-uppercase"><i class="uil uil-book-alt mr-1"></i> Meta Info</h5>
            <div class="row mb-2" v-if="client.marketingCategory">
              <div class="col-sm-12">
                <label class="font-weight-bold">Marketing Category</label><br/>
                <span>{{client.marketingCategory}}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-3">
                <label class="font-weight-bold">Created</label><br/>
                <span>{{client.created}}</span>
              </div>
              <div class="col-sm-3">
                <label class="font-weight-bold">Modified</label><br/>
                <span>{{client.modified}}</span>
              </div>
              <div class="col-sm-3">
                <label class="font-weight-bold">Version</label><br/>
                <span>{{client.version}}</span>
              </div>
            </div>
          </div>
          <div class="tab-pane" id="pets" :class="{show:tab=='pets', active:tab=='pets'}">
            <h5 class="mb-2 text-uppercase"><i class="mdi mdi-dog-side mr-1"></i> Pets</h5>
            <div class="row">
              <div class="col-md-12">
                <table class="table table-sm table-centered mb-0">
                  <thead>
                  <tr>
                    <th>Name</th>
                    <th>Species</th>
                    <th>Breed</th>
                    <th>Age</th>
                    <th>Weight</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="pet in pets" :key="pet._id">
                    <td><a href="" @click.prevent="navigateToPatient(pet._id)"><span :class="{'text-danger':pet.caution.value}">{{pet.name}}</span></a></td>
                    <td>{{pet.species}}</td>
                    <td>{{pet.color}} {{pet.sex}} {{pet.breed}}</td>
                    <td><span v-if="pet.age">{{pet.age.text}}</span></td>
                    <td><span v-if="pet.weight">{{pet.weight}} kg</span></td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="tab-pane" id="appointments" :class="{show:tab=='appointments', active:tab=='appointments'}">
            <h5 class="mb-2 text-uppercase"><i class="uil uil-schedule mr-1"></i> Appointments</h5>
            <div class="row">
              <div class="col-md-12">
                <table class="table table-sm table-centered mb-0">
                  <thead>
                  <tr>
                    <th>Pet</th>
                    <th>Date</th>
                    <th>Type</th>
                    <th>Treatments</th>
                    <th>Status</th>
                  </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(appointment, index) in flattenedAppointments" :key="index">
                      <td><a href="" @click.prevent="selectAppointment(appointment._id, appointment.visit.animalId)"><span :class="{'text-danger':appointment.visit.pet.caution.value}" v-if="appointment.visit.pet">{{appointment.visit.pet.name}}</span><span v-if="!appointment.visit.pet">{{appointment._id}}</span></a></td>
                      <td>{{appointment.date}}</td>
                      <td>{{appointment.type}}</td>
                      <td><span v-for="(treatment, index) in appointment.visit.treatments" :key="treatment.id"><span v-if="treatment.capacity >= 2">{{treatment.name}}</span><span v-if="index < (appointment.visit.treatments.length - 1) && treatment.capacity >= 2">, </span></span></td>
                      <td>{{appointment.visit.status}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="tab-pane" id="communication" :class="{show:tab=='communication', active:tab=='communication'}">
            <h5 class="mb-2 text-uppercase"><i class="uil uil-postcard mr-1"></i> Communication</h5>
          </div>
          <div class="tab-pane" id="history" :class="{show:tab=='history', active:tab=='history'}">
            <h5 class="mb-2 text-uppercase"><i class="uil uil-history mr-1"></i> Recent Changes</h5>
            <div class="timeline-alt pb-0">
              <div class="timeline-item" v-for="(change, index) in client.recentChanges.slice().reverse()" :key="index">
                <i class="mdi mdi-upload bg-info-lighten text-info timeline-icon"></i>
                <div class="timeline-item-info">
                  <a href="#" @click.prevent class="text-info font-weight-bold mb-1 d-block">{{change.modifiedBy}} {{change.result.result}}</a>
                  <small>{{change.result.message}}</small>
                  <p>
                    <small class="text-muted">{{change.result.occurredAt}}</small>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <appointment-card v-if="selectedAppointment" :id="selectedAppointment._id" :animal-id="selectedAppointment.selectedAnimalId" @navigated-to-owner="selectedAppointment=null"></appointment-card>
  </div>
</template>

<script>
// @ is an alias to /src

import PageTitle from '@/layout/PageTitle.vue';
import AppointmentCard from '@/components/appointments/AppointmentCard.vue';
import FullName from '@/components/FullName.vue';
import FullServiceClinic from '@/components/FullServiceClinic.vue';
import PostalAddress from '@/components/PostalAddress.vue';
import PhoneNumber from '@/components/PhoneNumber.vue';
import EmailAddress from '@/components/EmailAddress.vue';
import DirectoryAPI from '@/api/DirectoryAPI';
import SchedulingAPI from '@/api/SchedulingAPI';
import { useSession } from '@/store/session';
import { useAlerts } from '@/store/alerts';

export default {
  name: 'ClientDirectoryView',
  components: { PageTitle, AppointmentCard, FullName, FullServiceClinic, PostalAddress, PhoneNumber, EmailAddress },
  props: {
    id: String,
    tab: String
  }, setup(){
    const sessionStore = useSession();
    const alertStore = useAlerts();
    return { sessionStore, alertStore };
  },
  computed: {
    flattenedAppointments(){
      const result = [];
      if (this.appointments){
        for (const appointment of this.appointments){
          for (const visit of appointment.visits){
            const app = { ...appointment, visit };
            visit.pet = this.findPetById(visit.animalId);
            result.push(app);
          }
        }
      }
      return result.sort((a, b) => new Date(b.date) - new Date(a.date));
    }
  },
  created(){
    if (this.id){
      this.fetchClient(this.id);
    }
  },
  mounted(){

  },
  data(){
    return {
      client: null,
      pets: null,
      appointments: null,
      selectedAppointment: null
    };
  },
  methods: {
    navigateToClient: function(id){
      if (id){
        this.fetchClient(id);
        this.$router.push({ name: 'directory-client', query: { id, tab: this.tab } });
      }
    }, navigateToPatient: function(id){
      if (id){
        this.$router.push({ name: 'directory-patient', query: { id } });
      }
    }, fetchClient: function(id){
      if (id){
        this.client = null;
        DirectoryAPI.fetchClient(id).then(rs => {
          this.client = rs.data;
          this.saveClientTab(this.client);
        });

        DirectoryAPI.fetchClientAnimals(id).then(rs => {
          this.pets = rs.data;
        });

        SchedulingAPI.fetchClientAppointments(id).then(rs => {
          this.appointments = rs.data;
        });
      }
    }, newAddress: function(){
      if (this.client){
        if (!this.client.addresses){
          this.client.addresses = [];
        }

        this.client.addresses.push({ editing: true });
      }
    }, newPhoneNumber: function(){
      if (this.client){
        if (!this.client.phoneNumbers){
          this.client.phoneNumbers = [];
        }

        this.client.phoneNumbers.push({ editing: true });
      }
    }, newEmailAddress: function(){
      if (this.client){
        if (!this.client.emailAddresses){
          this.client.emailAddresses = [];
        }

        this.client.emailAddresses.push({ editing: true });
      }
    }, savePhoneNumber: function(updatedPhone){
      DirectoryAPI.saveClientPhone(this.id, updatedPhone).then(rs => {
        this.alertStore.addAlert({ type: 'success', ...rs.data });
      }).catch(e => {
        console.info(e);
        this.alertStore.addAlert({ type: 'error', ...e });
      });
    }, saveEmailAddress: function(updatedEmail){
      DirectoryAPI.saveClientEmail(this.id, updatedEmail).then(rs => {
        this.alertStore.addAlert({ type: 'success', ...rs.data });
      }).catch(e => {
        console.info(e);
        this.alertStore.addAlert({ type: 'error', ...e });
      });
    }, saveAddress: function(updatedAddress){
      DirectoryAPI.saveClientAddress(this.id, updatedAddress).then(rs => {
        this.alertStore.addAlert({ type: 'success', ...rs.data });
        updatedAddress.validationResult = { valid: true };
      }).catch(e => {
        console.info(e);
        this.alertStore.addAlert({ type: 'error', ...e });
        updatedAddress.validationResult = e.response.data;
      });
    }, saveName: function(updatedName){
      DirectoryAPI.saveClientName(this.id, updatedName).then(rs => {
        this.client.name = updatedName;
        this.alertStore.addAlert({ type: 'success', ...rs.data });
        this.saveClientTab(this.client);
      }).catch(e => {
        console.info(e);
        this.alertStore.addAlert({ type: 'error', ...e });
      });
    }, saveClinic: function(updatedClinic){
      DirectoryAPI.saveClientClinic(this.id, updatedClinic).then(rs => {
        this.alertStore.addAlert({ type: 'success', ...rs.data });
      }).catch(e => {
        console.info(e);
        this.alertStore.addAlert({ type: 'error', ...e });
      });
    }, saveClientTab: function(client){
      this.sessionStore.saveClientTab({ id: client._id, name: client.name });
    }, closeClientTab: function(id){
      this.sessionStore.clearClientTab(id);
    }, findPetById: function(id){
      let result = null;
      if (this.pets){
        for (const pet of this.pets){
          if (pet._id === id){
            result = pet; break;
          }
        }
      }
      return result;
    }, selectAppointment: function(appointmentId, animalId){
      if (this.appointments){
        for (const appointment of this.appointments){
          if (appointment._id === appointmentId){
            appointment.selectedAnimalId = animalId;
            this.selectedAppointment = appointment;
            break;
          }
        }
      }
    }
  }
};
</script>
