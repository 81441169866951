import axios from 'axios';
import { Client } from '@stomp/stompjs';
axios.defaults.withCredentials = true;

function isLocal(){
  return process.env.VUE_APP_CLINIC_ENV === 'local' || window.location.hostname.includes('localhost');
}

function isBeta(){
  return !isLocal() && window.location.hostname.includes('beta');
}

const authHeaders = { 'Content-Type': 'application/json', 'Cache-Control': 'no-cache', Authorization: 'Bearer ' };

const apis = {
  login: axios.create({
    baseURL: isLocal() ? 'http://localhost:8081/clinic' : (isBeta() ? 'https://api.beta.goodsampet.doctor/clinic' : 'https://api.goodsampet.doctor/clinic'),
    timeout: 30000,
    headers: { 'Cache-Control': 'no-cache' }
  }),
  clinic: axios.create({
    baseURL: isLocal() ? 'http://localhost:8081/clinic/api' : (isBeta() ? 'https://api.beta.goodsampet.doctor/clinic/api' : 'https://api.goodsampet.doctor/clinic/api'),
    timeout: 30000,
    headers: authHeaders
  }),
  directory: axios.create({
    baseURL: isLocal() ? 'http://localhost:8082/directory' : (isBeta() ? 'https://api.beta.goodsampet.doctor/directory' : 'https://api.goodsampet.doctor/directory'),
    timeout: 30000,
    headers: authHeaders
  }),
  medical: axios.create({
    baseURL: isLocal() ? 'http://localhost:8083/medical' : (isBeta() ? 'https://api.beta.goodsampet.doctor/medical' : 'https://api.goodsampet.doctor/medical'),
    timeout: 30000,
    headers: authHeaders
  }),
  scheduling: axios.create({
    baseURL: isLocal() ? 'http://localhost:8085/scheduling' : (isBeta() ? 'https://api.beta.goodsampet.doctor/scheduling' : 'https://api.goodsampet.doctor/scheduling'),
    timeout: 30000,
    headers: authHeaders
  })
};

export default {
  setAuthorization(authorization){
    authHeaders.Authorization = authorization;

    apis.clinic.defaults.headers = authHeaders;
    apis.directory.defaults.headers = authHeaders;
    apis.medical.defaults.headers = authHeaders;
    apis.scheduling.defaults.headers = authHeaders;
  }, loginAPI(){
    return apis.login;
  }, clinicAPI(){
    return apis.clinic;
  }, async newClinicWebSocketClient(){
    const session = (await apis.clinic.post('/ws/session')).data;
    return new Client({
      brokerURL: (isLocal() ? 'ws://localhost:8081/clinic/ws' : (isBeta() ? 'wss://api.clinic.beta.goodsampet.doctor:3443/clinic/ws' : 'wss://api.clinic.goodsampet.doctor:3443/clinic/ws')) + '?sessionId=' + session._id
    });
  }, directoryAPI(){
    return apis.directory;
  }, medicalAPI(){
    return apis.medical;
  }, schedulingAPI(){
    return apis.scheduling;
  }
};
