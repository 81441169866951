<template>
  <div class="row p-0 m-0">
    <div class="col-12 p-0 m-0" :class="{'bg-warning':df.isToday(day.date)}">
      <div class="clearfix p-0 m-0">
        <div class="spinner-border spinner-border-sm float-left" v-if="loading"></div>
        <div class="fc-daygrid-day-number float-right" :class="{'text-muted':other}" v-if="view=='month'">
          <a href="" @click="navigateToDate(day.date)">{{df.format(day.date, 'd')}}</a>
        </div>
      </div>
      <div class="card-group p-0 m-0" v-if="view=='week' || view=='day'" :class="{'bg-warning':df.isToday(day.date)}" style="min-height: 920px">
        <div class="card" v-if="workday" v-for="resource in workday.resources" :key="resource.bucket.bucketId" :class="{'bg-warning':df.isToday(day.date)}">
          <div class="card-header p-1">
            {{resource.name}} {{resource.bucket.type}}
          </div>
          <div class="card-body p-1 m-0">
            <work-resource :resource="resource"/>
          </div>
        </div>
      </div>
      <div class="card p-0 m-0" v-if="view=='month'" style="min-height:64px;" :class="{'bg-warning':df.isToday(day.date)}">
        <div class="card-body p-1 m-0">
          <div class="badge badge-primary" v-if="totalAppointments > 0">{{totalAppointments}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import * as dates from 'date-fns';
import SchedulingAPI from '@/api/SchedulingAPI';
import WorkResource from '@/components/appointments/WorkResource';

export default {
  name: 'WorkDay',
  components: {
    WorkResource
  },
  props: {
    view: String,
    day: Object,
    other: Boolean
  },
  computed: {
    totalAppointments(){
      let count = 0;
      if (this.workday){
        for (const resource of this.workday.resources){
          for (const reservation of resource.bucket.reservations){
            if (reservation){
              count = count + 1;
            }
          }
        }
      }
      return count;
    }
  },
  created(){
    this.$watch('day', (newDate) => {
      if (this.day && this.day.date){
        this.loadWorkday(this.day.date);
      }
    }, { immediate: true });
  },
  mounted(){

  },
  data(){
    return {
      df: dates,
      loading: false,
      workday: null
    };
  },
  methods: {
    loadWorkday: function(date){
      this.loading = true;
      SchedulingAPI.fetchWorkday(date.toISOString()).then((rs) => {
        this.workday = rs.data;
      }).catch((er) => {
        console.log(er);
      }).finally(() => {
        this.loading = false;
      });
    }, navigateToDate: function(date){
      this.$router.push({ name: 'appointments', query: { view: 'day', date: dates.format(date, 'yyyy-M-d') } });
    }
  }
};
</script>
