<template>
  <tr v-if="week">
    <td class="p-0" v-for="day in week.days" :key="day.date">
      <work-day :view="view" :day="day" :other="!df.isSameMonth(day.date, week.dateThatActivated)"/>
    </td>
  </tr>
</template>

<script>
// @ is an alias to /src
import * as dates from 'date-fns';
import WorkDay from '@/components/appointments/WorkDay';

export default {
  name: 'WorkWeek',
  components: {
    WorkDay
  },
  props: {
    view: String,
    week: Object
  },
  computed: {

  },
  created(){

  },
  mounted(){

  },
  data(){
    return {
      df: dates
    };
  },
  methods: {

  }
};
</script>
